<style>
	@font-face {
		font-family: 'UnidreamLED';
		src: url('@/assets/font/UnidreamLED.eot');/* 兼容ie9 */
		src:url('@/assets/font/UnidreamLED.eot?#iefix')format('embedded-opentype'),/* 兼容ie6-8 */
		url('@/assets/font/UnidreamLED.woff') format('woff'),
		local('UnidreamLED'),url('@/assets/font/UnidreamLED.woff')
	}
	.vjs-has-started .vjs-control-bar{
        display: none !important;
    }
	*{
		cursor: url('@/assets/images/common/cursor.ico'), auto !important;
	}
   	.app{
		-webkit-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
	.backface-hidden{
		backface-visibility: hidden;
	}
	.bg{
		/* background: linear-gradient(90deg, #081118 0, #173247 50%, #081118 100%); */
		background: linear-gradient(90deg, #000521 0, #004c86 50%, #000521 100%);
		background-size: 100% 100%;
	}
	.bg-cyan{
		  background: linear-gradient(180deg,rgba(108,171,163,1) 0%,rgba(128,213,209,1) 20%, rgba(72,118,157,1) 100%);
		/* background: linear-gradient(180deg,rgba(30,30,30,.8) 0%,rgba(255,222,125,.8) 10%,rgba(255,255,255,.8) 20%,rgba(255,222,125,.5) 40%, rgba(30,30,30,.5) 60%); */
	}
	.bg-cyan-hover:hover{
		background-color: #e9ecef;
	}
	.bg-yellow{
		  background-color: #fff110 !important;
	}
	.bg-silver{
		background-color: silver !important;
	}
	.bg-coppery{
		background-color:Chocolate  !important;
	}
	.bg-highlight{
		background-image: linear-gradient(90deg, rgba(0, 0, 0, .2), rgba(255, 255, 255, .3), rgba(0, 0, 0, .2)) !important;
	}
	.bg-navy{
        background:linear-gradient(0deg,#00345b 10%,#005ea6 80%); 
        box-shadow:0 0.1rem 0.1rem 0.05rem #0e0e0e, inset 0 0.05rem 0px 0px rgba(255, 255, 255, 0.7);
    }
    .bg-blue{
        background-color: #005ea6 !important;
    }
	.bg-lawngreen{
		background: lawngreen;
	}
    .btn-navy.active{
        box-shadow: 0 0 0 0.05rem #0e0e0e !important;
        transform: translateY(.1rem);
    }
	.border-_3{
		border-width: 0.3rem;
	}
	.border-style-outset{
		border-style: outset;
	}
	.border-dashed{
		border-style: dashed !important;
	}
	.border-double{
		border-style: double;
	}
	.bottom-5{
		bottom: 5%;
	}
	.bottom-10{
		bottom:10%;
	}
    .h-0{
      	height: 0;
    }
	.h-1_5rem{
		height: 1.5rem;
	}
	.cursor-grab{
		cursor: grab;
	}
	.cursor-grabbing{
		cursor: grabbing;
	}
	.fs-_6rem{
		font-size: .6rem;
	}
	.scroll-none::-webkit-scrollbar{
		display: none;
	}
    .min-w-0{
		min-width: 0 !important;
    }
    .min-w-100{
		min-width: 100%;
    }
    .min-h-100{
		min-height:100%;
    }
	.mt-_2_5p{
		margin-top: -2.5%;
	}
	.mt-_15rem{
		margin-top: .15rem;
	}
	.me-_15rem{
		margin-right: .15rem;
	}
	.w-0{
		  width:0;
	}
	.w-05rem{
		width: .5rem !important;
	}
	.w-_8rem{
		width: .8rem;
	}
    .w-1rem{
		width: 1rem !important;
    }
	.w-1_2rem{
		width: 1.2rem;
	}
	.w-1_5rem{
		width: 1.5rem;
	}
	.w-2rem{
		width: 2rem !important;
	}
	.w-2_5rem{
		width: 2.5rem !important;
	}
	.w-3rem{
		width:3rem;
	}
	.w-3_5rem{
		width:3.5rem;
	}
	.w-4rem{
		width: 4rem;
	}
	.w-4_6rem{
		width: 4.6rem;
	}
	.w-5rem{
		width: 5rem;
	}
	.w-6rem{
		width: 6rem;
	}
	.w-7rem{
		width: 7rem;
	}
	.w-8rem{
		width: 8rem;
	}
	.w-9rem{
		width: 9rem;
	}
	.w-10rem{
		width: 10rem;
	}
	.w-14rem{
		width: 14rem;
	}
	.w-15rem{
		width: 15rem;
	}
	.w-25rem{
		width: 25rem;
	}
	.w-30rem{
		width:30rem;
	}
	.w-50rem{
		width: 50rem;
	}
	.w-70rem{
		width: 70rem;
	}
	.w-60{
		width: 60%;
	}
	.w-70{
		width: 70%;
	}
	.w-80{
		width: 80%;
	}
	.w-88{
		width: 88%;
	}
	.w-90{
		width: 90%;
	}
	.w-95{
		width: 95%;
	}
	.w-130{
		width: 130%;
	}
	.h-_8rem{
		height: .8rem;
	}
    .h-1rem{
		height:1rem;
    }
	.h-2rem{
		height: 2rem;
	}
	.h-3rem{
		height:3rem;
	}
	.h-3_5rem{
		height: 3.5rem;
	}
	.h-4rem{
		height: 4rem;
	}
	.h-4_5rem{
		height: 4.5rem;
	}
	.h-5rem{
		height: 5rem;
	}
	.h-8rem{
		height: 8rem;
	}
	.h-9_5rem{
		height: 9.5rem;
	}
	.h-10rem{
		height: 10rem;
	}
	.h-11rem{
		height: 11rem;
	}
	.h-20rem{
		height:20rem;
	}
	.h-30rem{
		height:30rem;
	}
	.h-33{
		height: 33.333333%;
	}
	.h-78{
		height: 78%;
	}
	.h-80{
		height: 80%;
	}
	.h-90{
		height: 90%;
	}
	.h-130{
		height: 130%;
	}
	.pt-6_2rem{
		padding-top: 6.2rem;
	}
	.pt-9rem{
		padding-top: 9rem;
	}
	.pt-60{
		padding-top: 60%;
	}
	.pt-10{
		padding-top: 10%;
	}
	.pt-100{
		padding-top: 100%;
	}
    .rotate-30{
      	transform: rotate(30deg);
    }
	.rotate-45{
      	transform: rotate(45deg);
    }
	.rotate-90{
		transform: rotate(90deg);
	}
	.rotate-y-180{
		transform:rotateY(180deg);
	}
	.init-rotate-345{
		rotate: 345deg;
	}
	.init-translate-middle{
		translate: -50% -50%;
	}
    .top-2rem{
      	top:2rem;
    }
	.top-7{
		top:7%;
	}
	.top-8{
		top:8%;
	}
	.top-13{
		top: 13%;
	}
	.top-4_5rem{
		top:4.5rem;
	}
	.top-16{
		top:16%;       
	}
	.top-26{
		top:26%;
	}
	.top-28{
		top:28%;
	}
	.top-40{
		top:40%;
	}
	.top-55{
		top:55%;
	}
	.top-60{
		top:60%;
	}
	.top-70{
		top:70%;
	}
	.transform-3d{
		transform-style: preserve-3d;
	}
    .start-2rem{
      	left:2rem;
    }
	.start-110{
		left: 110%;
	}
	.end--110{
		right: -110%;
	}
	.end-2rem{
		right: 2rem;
	}
	.end-4rem{
		right: 4rem;
	}
    .vertical-middle{
      	vertical-align: middle;
    }
    .border-solid{
      	border-style: solid;
    }
	.disabled{
		cursor: no-drop !important;
		pointer-events:auto !important;
	}
	.index-_1{
		z-index: -1;
	}
	.index-0{
		z-index: 0;
	}
	.index-1{
		z-index:1;
	}
	.index-2{
		z-index: 2;
	}
	.index-11{
		z-index:11;
	}
	.index-1051{
		z-index:1051;
	}
	.index-1052{
		z-index:1052;
	}
	.index-1053{
		z-index: 1053;
	}
	.index-1054{
		z-index: 1054;
	}
	.translate-start-x-middle-y{
		transform:translate(-100%,-50%);
	}
	.translate-middle-x-end-y{
		transform:translate(-50%,100%);
	}
	.translate-end-y{
		transform: translate(0,-100%);
	}
	.translate-start-y{
		transform: translate(0,100%);
	}
	.translate-middle-y{
		transform: translate(0,-50%);
	}
	.translate-start-x{
		transform: translateX(100%);
	}
	.translate-end-x{
		transform: translateX(-100%);
	}
	.border-cyan{
        border-color: #64a7c4 !important;
		/* border-color: #ffb338 !important; */
		border-style:solid;
    }
    .rounded-5{
        border-radius: 0.5rem!important;
    }
	.rounded-10{
		border-radius: 1rem!important;
	}
	.rounded-start-top-10{
		border-top-left-radius: 1rem;
	}
	.rounded-start-bottom-10{
		border-bottom-left-radius: 1rem;
	}
	.rounded-end-top-10{
		border-top-right-radius: 1rem;
	}
	.rounded-end-bottom-10{
		border-bottom-right-radius: 1rem;
	}
    .text-yellow{
        color: #fff110 !important;
		/* color:rgba(190,179,0) !important; */
    }
	.text-silver{
		color: silver !important;
	}
	.text-coppery{
		color:Chocolate  !important;
	}
	.text-saddlebrown{
		color: saddlebrown;
	}
	.text-transparent{
		color:transparent;
	}
    .border-yellow{
        border-color: #fff110 !important;
		border-style:solid;
    }
	.border-jasper{
		border-color: #035522 !important;
		border-style:solid;
	}
	.border-start-jasper{
		border-left: 1px solid #035522;
	}
	.border-end-jasper{
		border-right: 1px solid #035522;
	}
	.overflow-x-scroll{
        overflow-x: scroll;
    }
	.overflow-y-scroll{
        overflow-y: scroll;
    }
	.border-solid{
		border-style: solid;
	}
	.border-transparent{
		border-color: transparent !important;
		border-style: solid;
	}
	.border-end-style-double{
		border-right-style: double !important;
	}
	.border-end-style-dashed{
		border-right-style: dashed !important;
	}
	video{
        object-fit: fill !important;
    }
	.container-xxxl{
		width: 100%;
		/* padding-right: var(--bs-gutter-x, 0.75rem);
		padding-left: var(--bs-gutter-x, 0.75rem); */
		margin-right: auto;
		margin-left: auto;
	}
	.width-max{
		width: max-content;
	}
	.text-shadow-white{
        text-shadow:var(--bs-light) 1px 0 1px, var(--bs-light) 0 1px 1px, var(--bs-light) -1px 0 1px, var(--bs-light) 0 -1px 1px;
    }
	.text-spacing--_1rem{
		letter-spacing: -.1rem;
	}
	.text-spacing-_1{
        letter-spacing: .1rem;
    }
	.text-spacing-_3{
		letter-spacing: .3rem;
	}
	/* 文本竖直显示 */
	.text-vertical{
		writing-mode: vertical-lr;
	}
	.text-upright{
		text-orientation: upright;
	}
	.textarea-noresize{
        resize:none;
    }
	.layer-absolute::after{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, .4);
	}
	.lh-2-4{
        line-height: 2.4 !important;
    }
    .lh-3{
        line-height: 3;
    }
	.lh-4{
		line-height: 4;
	}
	.rotate{
        transform: rotateY(90deg);
        animation: rotate .8s ease .1s forwards;
    }
	.perspective-600{
        perspective: 600px;
    }
	.btn-navy{
        background:linear-gradient(0deg,#00345b 10%,#005ea6 80%); 
        box-shadow:0 0.1rem 0.1rem 0.05rem #0e0e0e, inset 0 0.05rem 0px 0px rgba(255, 255, 255, 0.7);
    }
    .btn-navy:hover{
        background:linear-gradient(0deg,#00345b 10%,#005ea6 50%); 
    }
    .btn-navy:active{
        box-shadow: 0 0 0 0.05rem #0e0e0e !important;
        transform: translateY(.1rem);
    }
    .btn-shadow-right{
        box-shadow: -1px 3px 2px 0px #000;
    }
    .btn-shadow-left{
        box-shadow: 1px 3px 2px 0px #000;
    }
    .chips-shadow{
        box-shadow: 1px 3px 2px 0px #000;
    }
    .chips.active{
        top:-2px;
        box-shadow: 1px 3px 3px 3px yellow;
    }
    .chips:hover{
        top:-2px;
        transition:top 10s linear;
    }
    .chips:not(.active):hover>.chips-shadow{
        box-shadow: 1px 5px 2px 0px #000;
    }
    .chips-text{
        transform: translate(-50%,-50%) rotateX(35deg) !important;
        transform-origin: top;
        /* text-shadow: -1px 1px 0px #fff; */
        text-shadow: 1px 1px 0px #fff;
    }
	.virtual-container{
        transform:translateX(-50%);
    }
	.bg-jasper{
		background: #035522;
	}
	.shadow-hover{
		transition: box-shadow .15s linear;
	}
	.shadow-hover:hover{
		box-shadow: .1em .1em .3em .1em #555;
	}
	.scale-hover{
		transition: transform .1s linear;
	}
	.scale-hover:hover{
		/* transform: scale(1.008); */
		transform: scale(1.015);
	}
	.scale-_7{
		transform: scale(.7);
	}
	.font-led{
		font-family: 'UnidreamLED';
	}
	.zoom-_7{
		zoom: .7;
	}
	.zoom-_8{
		zoom: .8;
	}
    @keyframes rotate {
        0% {
            transform: rotateY(90deg);
        }
        100% {
            transform: rotateY(0deg);
        }
    }
	.marquee{
        animation: marquee 15s linear infinite;
    }
    .notice-short:hover~.notice-long{
        display: block;
    }
    .notice-long{
        display: none;
        margin-top: -0.25rem;
    }
    @keyframes marquee {
        0%{
            left:100%;
        }
        100%{
            left: -100%;
        }
    }
	/* 反面 */
    .poker-bg{
        background: url(@/assets/images/room/texasauto/poker_bg.png) no-repeat ;
        background-size:100% 100% ;
        transform: rotateY(180deg);
        transition: transform 200ms linear , box-shadow 100ms linear;
    }
	.poker-bg.danger{
		background-image: url(@/assets/images/common/poker_bg_2.png);
	}
    .poker-bg>div{
        opacity:0;
    }
    /* 正面 */
    .poker-bg.show{
        animation: show-poker-rotate 200ms linear forwards;
    }
    .poker-bg.show>div{
        animation: show-poker-opacity 100ms 100ms linear forwards;
    }
	/* .poker-bg.animation-duration-0>div{
        animation-duration: 0s !important;
        animation-delay:0s !important;
    } */
	.animation-duration-0{
		animation-duration: 0s !important;
		animation-delay:0s !important
	}
    @keyframes show-poker-rotate {
        to{
            transform: rotateY(0deg);
        }
    }
    @keyframes show-poker-opacity {
        to{
            opacity:1;
        }
    }
	@keyframes rays-transform-rotate {
        100% {
            transform: rotate(360deg);
        }
    }
	@keyframes rays-rotate {
		0%{
			transform: translate(-50%, -50%);
		}
        100% {
			transform:translate(-50%,-50%) rotate(360deg);
        }
    }
	@keyframes rays-scale{
		0%{
			width: 80%;
			height: 80%;
		}
		100% {
			width: 140%;
			height: 140%;
        }
	}
	@keyframes img-scale{
		from{
			transform: translate(-50%, -50%) scale(1);
		}
        to{
            transform: translate(-50%, -50%) scale(1.35);
        }
    }
	@keyframes img-jump{
		0%{
			transform: translate(-50%, -50%) scale(1.2,.8);
			margin-top:1rem;
		}
        100%{
            transform: translate(-50%, -50%) scaleY(.9,1.1);
			margin-top:-1rem;
        }
    }
	@keyframes light-circle{
		0%{
			transform: scale(.9);
			box-shadow: rgba(255,60,60,.6) 0 0 1rem, inset rgb(255 0 0) 0 0 .5rem;
		}
		30%{
			transform: scale(1);
			box-shadow: rgba(0,206,255,0) 0 0 1rem 2rem, inset rgb(255 90 90) 0 0 1rem;
		}
		100%{
			transform: scale(.9);
			box-shadow: rgba(0,206,255,0) 0 0 1rem, inset rgb(255 0 0) 0 0 .5rem;
		}
	}
	@keyframes start-circle{
		0%{
			transform: scale(.9);
			box-shadow: rgba(255, 255, 60, 0.6) 0 0 1rem;
		}
		30%{
			transform: scale(1);
			box-shadow: rgba(0,206,255,0) 0 0 1rem 2rem;
		}
		100%{
			/* transform: scale(.9); */
			box-shadow: rgba(0,206,255,0) 0 0 1rem;
		}
	}
	@keyframes opacity{
		from{
			opacity: 1;
		}
		to{
			opacity: 0;
		}
	}
	.layer-my-class .layui-m-layerbtn{
		border:none;
		background: linear-gradient(90deg, #000521 0, #004c86 50%, #000521 100%);
		display: flex;
		justify-content: center;
		height:auto;
		line-height: unset;
	}
	.layer-my-class .layui-m-layerbtn>*{
		border:0 !important;
		border-radius: 50rem !important;
		padding: .25rem 1.5rem;
		margin: 0 .5rem 1.25rem .5rem;
		box-shadow: 0 0 0.2rem 0px #000;
		font-weight: bold;
		color: #fff !important;
	}
	.layer-my-class .layui-m-layerbtn>span{
		background: linear-gradient(180deg,#fff,rgb(0, 110, 255) 35%);
	}
	.layer-my-class .layui-m-layerbtn>span:hover{
		background: linear-gradient(180deg,#fff,rgb(0, 110, 255) 60%);
	}
	.layer-my-class .layui-m-layerbtn>span[yes]{
		background: linear-gradient(180deg,#fff,#cbcb00 35%);
	}
	.layer-my-class .layui-m-layerbtn>span[yes]:hover{
		background: linear-gradient(180deg,#fff,#cbcb00 60%);
	}
    @media (min-width: 576px){
		.col-sm-0{
			flex: 0 0 auto;
			width: 0 !important;
		}
		.h-sm-0{
			height: 0 !important;
		}
		.h-sm-50{
			height: 50% !important;
		}
		.h-sm-100{
			height:100% !important;
		}
		.h-sm-6rem{
			height: 6rem;
		}
		.h-sm-8rem{
			height: 8rem;
		}
		.h-sm-4_5rem{
			height: 4.5rem;
		}
		.h1-sm{
    		font-size: calc(1.375rem + 1.5vw);
		}
		.w-sm-7rem{
			width: 7rem;
		}
		.w-sm-9rem{
			width: 9rem;
		}
		.w-sm-60{
			width: 60%;
		}
		.lh-sm-1{
			line-height: 1 !important;
		}
		.lh-sm-base {
			line-height: 1.5 !important;
		}
		.lh-sm-2{
			line-height: 2 !important;
		}
		.position-sm-absolute {
			position: absolute !important;
		}
		.position-sm-relative{
			position: relative !important;
		}
		.top-sm-5rem{
			top:5rem;
		}
		.top-sm-0{
			top:0;
		}
		.top-sm-4_5rem{
			top:4.5rem !important;
		}
		.top-sm-26{
            top:26%;
        }
		.bottom-sm-0{
			bottom: 0;
		}
		.bottom-sm-auto{
			bottom: auto;
		}
		.start-sm-0{
			left: 0;
		}
		.start-sm-110{
			left: 110%;
		}
		.start-sm-3_75{
			left: 3.75rem;
		}
		.border-top-sm {
			border-top: 1px solid #dee2e6 !important;
		}
		.border-bottom-sm {
			border-bottom: 1px solid #dee2e6 !important;
		}
		.border-start-sm {
			border-left: 1px solid #dee2e6 !important;
		}
		.border-end-sm {
			border-right: 1px solid #dee2e6 !important;
		}
		.border-top-sm-0{
			border-top: 0!important;
		}
		.border-bottom-sm-0{
			border-bottom: 0!important;
		}
		.border-start-sm-0{
			border-left: 0!important;
		}
		.border-sm {
			border: 1px solid #dee2e6!important;
		}
		.border-sm-0{
			border-width: 0px!important;
		}
		.border-sm-2{
			border-width: 2px!important;
		}
		.border-sm-3 {
			border-width: 3px!important;
		}
		.border-sm-4{
			border-width: 4px!important;
		}
		.img-sm-thumbnail{
			padding: 0.25rem;
			background-color: #fff;
			border: 1px solid #dee2e6;
			border-radius: 0.25rem;
			max-width: 100%;
			height: auto;
		}
		.img-sm-fluid {
			max-width: 100%;
			height: auto;
		}
		.fs-sm-2{
			font-size: calc(1.325rem + .9vw)!important;
		}
		.fs-sm-4{
			font-size: calc(1.275rem + .3vw)!important;
		}
		.fs-sm-5{
			font-size: 1.25rem!important;
		}
		.top-sm-26{
			top:26%;
		}
		.top-sm-100{
			top:100%;
		}
		.w-sm-3rem{
			width: 3rem;
		}
		.w-sm-3_5rem{
			width: 3.5rem;
		}
		.w-sm-4rem{
			width: 4rem;
		}
		.w-sm-80{
			width: 80%;
		}
		.rounded-sm-10{
			border-radius: 1rem!important;
		}
		.rotate3d-x-sm-50{
            transform:rotate3d(1,0,0,50deg);
            transform-origin: bottom;
        }
		.teach>div:first-child{
			width:2rem;
			height:2rem;
			transform: translate(50%, 1rem) rotate(45deg);
		}
		.teach>div:nth-child(2){
			transform: rotateX(-180deg) translate(-100%,60%);
		}
		.teach-chat{
			width:15rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			transform: rotateX(180deg);
			right:11.5rem;
			top:7rem;
		}
		.teach-personal{
			width:18rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			transform: rotateX(180deg);
			right:8rem;
			top:7rem;
		}
		.teach-settings{
			width:18rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			transform: rotateX(180deg);
			right:5rem;
			top:7rem;
		}
		.text-sm-vertical{
			writing-mode: vertical-lr;
		}
    }
    @media (min-width: 768px){
		.lh-md-1{
			line-height: 1 !important;
		}
		.lh-md-2{
			line-height: 2 !important;
		}
		.w-md-4rem{
			width:4rem;
		}
		.border-md-3{
			border-width: 3px!important;
		}
    }
    @media (min-width: 992px){
		.border-lg-4{
			border-width: 4px!important;
		}
		.start-lg-0{
			left:0;
		}
		.bottom-lg-0{
			bottom:0;
		}
		.top-lg-auto{
			top:auto !important;
		}
    }
	@media (min-width: 1200px){
		.lh-xl-2{
			line-height: 2 !important;
		}
		.lh-xl-4{
			line-height: 4 !important;
		}
		.top-xl-6rem{
			top:6rem;
		}
    }
    @media (max-width: 575px){
		.fs-xs-1{
			font-size: calc(1.375rem + 1.5vw)!important;
		}
		.fs-xs-2{
			font-size: calc(1.325rem + .9vw)!important;
		}
		.fs-xs-5{
			font-size: 1.25rem !important;
		}
		.small-xs{
			font-size: 0.875em;
		}
		.h-xs-0{
			height: 0;
		}
		.h-xs-50{
			height:50% !important;
		}
		.h-xs-100{
			height: 100% !important;
		}
		.h-xs-33{
			height: 33.3333333333%;
		}
		.h-xs-3rem{
			height: 3rem;
		}
		.lh-xs-1{
			line-height: 1;
		}
		.lh-xs-sm {
			line-height: 1.25 !important;
		}
		.lh-xs-2{
			line-height: 2;
		}
		.lh-xs-3{
			line-height: 3 !important;
		}
		.d-xs-block{
			display: block !important;
		}
		.bg-xs-transparent{
			background: transparent !important;
		}
		.bg-xs-dark{
			--bs-bg-opacity: 1;
  			background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
		}
		.rounded-xs {
			border-radius: 0.25rem !important;
		}
		.border-xs{
			border: 1px solid #dee2e6!important;
		}
		.border-xs-2{
			border-width: 2px!important;
		}
		.border-xs-3{
			border-width: 3px!important;
		}
		.rounded-xs {
			border-radius: 0.25rem!important;
		}
		.position-xs-absolute {
			position: absolute !important;
		}
		.position-xs-relative {
			position: relative !important;
		}
		.position-xs-fixed{
			position:fixed;
		}
		.pt-xs-60{
			padding-top: 60%;
		}
		.top-xs-0{
			top:0 !important;
		}
		.top-xs-2_25{
			top: 2.25rem;
		}
		.start-xs-0{
			left: 0;
		}
		.start-xs-3_75{
			left:3.75rem;
		}
		.start-xs-50{
			left: 50%;
		}
		.end-xs-0{
			right: 0;
		}
		.bg-xs-success{
			background-color: rgba(25,135,84,1) !important;
		}
		.bottom-xs-0{
			bottom:0;
		}
		.border-top-xs{
			border-style: solid !important;
		}
		.border-start-xs-0{
			border-left: 0 !important;
		}
		.border-end-xs-0{
			border-right: 0 !important;
		}
		.border-bottom-xs {
			border-bottom: 1px solid #dee2e6 !important;
		}
		.border-bottom-xs-0{
			border-bottom: 0 !important;
		}
		.border-bottom-xs-2{
			border-bottom: 2px !important;
		}
		.border-bottom-xs-style-dashed{
			border-bottom-style: dashed !important;
		}
		.border-end-xs-style-solid{
			border-right-style: solid !important;
		}
		.top-xs-3_5rem{
			top:3.5rem;
		}
		.top-xs-50{
			top: 50%;
		}
		.translate-50-y-xs{
			transform:translateY(50%);
		}
		.translate-100-y-xs{
			transform:translateY(100%);
		}
		.translate-middle-xs{
			transform: translate(-50%,-50%);
		}
		.w-xs-100{
			width: 100%;
		}
		.w-xs-2_5rem{
			width: 2.5rem;
		}
		.w-xs-4rem{
			width: 4rem;
		}
		.w-xs-22rem{
			width: 22rem;
		}
		.teach-chat>div:first-child{
			width:2rem;
			height:2rem;
			left: 0;
    		transform: translate(-50%, 1rem) rotate(45deg);
		}
		.teach-chat>div:nth-child(2){
			transform: translate(50%,-100%);
		}
		.teach-personal>div:first-child{
			width:2rem;
			height:2rem;
			right: 0;
    		transform: translate(50%, 1rem) rotate(45deg);
		}
		.teach-personal>div:nth-child(2){
			transform: translate(-50%,-100%);
		}
		.teach-settings>div:first-child{
			width:2rem;
			height:2rem;
			right: 0;
    		transform: translate(50%, 1rem) rotate(45deg);
		}
		.teach-settings>div:nth-child(2){
			transform: translate(-50%,-100%) rotateX(180deg);
		}
		.teach-chat{
			width:11rem;
			height:15rem;
			border-left: 3px solid #fff;
			border-top-left-radius: 100%;
			/* transform: rotateX(180deg); */
			left:5rem;
			bottom:6rem;
		}
		.teach-personal{
			width:11rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			/* transform: rotateX(180deg); */
			right:5rem;
			bottom:6rem;
		}
		.teach-settings{
			width:14rem;
			height:15rem;
			border-right: 3px solid #fff;
			border-top-right-radius: 100%;
			transform: rotateX(180deg);
			right:2rem;
			top:6rem;
		}
		.scale-top-sm-1_15{
            transform:scale(1.15);
            transform-origin: top;
        }
		.index-xs-1{
			z-index: 1;
		}
    }
    @media (min-width: 576px) and (max-width:767px){
		.small-sm{
			font-size: 0.875em;
		}
		.scale-submit{
            transform-origin: 50% 100%;
            transform: translateX(-50%) scale(1.3) !important;
            transition: transform .15s linear;
        }
    }
    @media (min-width: 768px) and (max-width: 991px){
		.small-md{
			font-size: 0.875em;
		}
    }
	@media (min-width:1700px) {
		/* .container-xxxl{
			max-width: 1700px;
		} */
	}

	:root{
		/* --outer-red:#bd3a5d;
        --inside-red:#cf8186; */
        /* --inside-blue:#64a7c4;
        --outer-blue:#48769d; */
		--outer-red:#00304C;
        --inside-red:#004f7d;
		--inside-blue:#00304C;
        --outer-blue:#001e31;
		--start:0%;
	}
	/* 深色模式 */
	.darkness{
		--bs-success-rgb:16,86,53;
		--bs-secondary-rgb:64,70,74;
		--bs-warning-rgb: 207,157,6;
		--bs-light-rgb: 202,202,202;
		--bs-primary-rgb: 10,87,201;
		--bs-info-rgb:10,159,189;
		--bs-danger-rgb: 172,41,54;

		--bs-success:#105635;
		--bs-secondary: #40464a;
		--bs-warning: #cf9d06;
		--bs-light:#cacaca;
		--bs-primary:#0a57c9;
		--bs-info:#0a9fbd;
		--bs-danger: #ac2936;

		--outer-red:#a43251 !important;
        --inside-red:#b77277 !important;
        --inside-blue:#5893ac !important;
        --outer-blue:#3d6485 !important;
	}
	.darkness .border-bottom{
		border-bottom: 1px solid var(--bs-gray-400)!important;
	}
	.darkness .border-warning{
		border-color: var(--bs-warning) !important;
	}
	.darkness .border-info{
		border-color:var(--bs-info) !important;
	}
	.darkness .border-light{
		border-color: var(--bs-light) !important;
	}
	.darkness .border-primary{
		border-color:var(--bs-primary) !important;
	}
	.darkness .btn-warning{
		background-color: var(--bs-warning) !important;
    	border-color: var(--bs-warning) !important;
	}
	.darkness .btn-primary{
		background-color:var(--bs-primary);
		border-color:var(--bs-primary);
	}
	.darkness .btn-outline-light{
		border-color:var(--bs-gray-300);
		color:var(--bs-gray-300);
	}
	.darkness .form-check-input:checked {
		background-color: var(--bs-primary) !important;
		border-color: var(--bs-primary) !important;
	}
	.darkness .form-check-input{
		background-color: var(--bs-light);
	}
	.darkness .img-thumbnail{
		background-color: var(--bs-light);
	}
	.darkness .bg-cyan{
		background:linear-gradient(180deg,rgba(76,119,114,1) 0%,rgba(98,164,161,1) 20%, rgba(48,78,104,1) 100%);
	}
	.darkness .nav-tabs{
		border-bottom: 1px solid var(--bs-gray-400)!important;
	}
	.darkness .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
		background-color:var(--bs-light) !important;
		border-color: var(--bs-gray-400) var(--bs-gray-400) var(--bs-light) !important;
	}
	.darkness .chips.active{
		box-shadow: 1px 3px 3px 3px #cdcd00;
	}
	.darkness .text-yellow{
		color: #cdcd00 !important;
	}
	.darkness .btn-check:checked+.btn-outline-blue,.btn-outline-blue:hover {
        color: var(--bs-light) !important;
    }
	.darkness .page-item.disabled .page-link{
		background-color: var(--bs-light);
		border-color:var(--bs-gray-400);
		color:var(--bs-gray-700);
	}
	.darkness .page-item.active .page-link{
		color:var(--bs-light);
		background-color:var(--bs-primary);
		border-color:var(--bs-primary);
	}
	.darkness .list-group-item-primary{
		color: #052d67;
    	background-color: #aab9d0;
	}
	.darkness .list-group-item-dark{
		background-color:#a0a0a1;
	}
	.darkness .list-group-item-info{
		color: #03282f;
		background-color:#a7c5cb;
	}
	.darkness .modal-footer{
		border-top:1px solid var(--bs-gray-400);
	}
	.darkness .modal-header{
		border-bottom:1px solid var(--bs-gray-400);
	}
	.darkness .drag-progress,.darkness .verify-msg{
        background-color: #5b912c;
    }

	/* .darkness .border-cyan{
		border-color: #949494 !important;
	}
	.darkness .border-yellow{
		border-color: rgb(231, 220, 0) !important;
	}
	.darkness.bg{
		background: #000 !important;
	}
	.darkness .game-list-item.active::before {
		content: "";
		display: block;
		background: rgba(0,0,0,.1);
		width: 100%;
		height: 100%;
		position: absolute;
	} */
	.show-width{
		animation: show-width .5s linear both ;
		width: 0;
	}
	@keyframes show-width {
		to{
			width: 100%
		}
	}
</style>
<template>
	<span class="d-none text-light justify-content-center" :class="{'d-sm-flex':$route.name=='share'}" style="font-size:24px">{{$t("app.useVertical")}}</span>
	<div :class="[skin]" class="bg w-100 h-100 d-flex align-items-center">
		<div id="wrapper" class="d-flex flex-column container-xxxl overflow-hidden position-relative bg-gradient" :class="{'d-sm-none':$route.name=='share'}">
			<div class="flex-grow-1 h-0 order-last">
				<router-view
				@ws-send="wsSend" 
				@ws-msg="v=>ws&&(wsMsgFn=v)" 
				:is-fullscreen="isFullscreen"
				:has-chat-msg="hasChatMsg" />
			</div>
			<!-- 广播公告 -->
			<div v-html="broadcastHtml" class="position-absolute start-50 translate-middle-x overflow-hidden index-2 mt-2 show-width text-center" style="background: linear-gradient(90deg,transparent,#000,transparent);"></div>
			<!-- 客服消息提示框 -->
			<div class="position-fixed bottom-0 end-0 p-3 index-11">
				<div id="liveToast" class="toast" :class="{'show':fastChatMsg.msg!=''}" role="alert" aria-live="assertive" aria-atomic="true">
					<div class="toast-header">
						<strong class="me-auto">{{fastChatMsg.serverName}}</strong>
						<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
					<div class="toast-body">{{fastChatMsg.msg}}</div>
				</div>
			</div>
			<!-- 引导 -->
			<div v-if="isShowTeach&&$route.name!='login'" class="position-absolute top-0 start-0 bottom-0 end-0 bg-black bg-opacity-75 index-1052"></div>
			<div v-if="isShowTeach&&$route.name!='login'" class="position-absolute top-0 start-0 bottom-0 end-0 index-1054">
				<div class="position-absolute" :class="teachList[teachIndex].class">
					<div class="position-absolute border border-3 border-white border-start-0 border-top-0 bottom-0 end-0"></div>
					<div class="position-absolute text-light fw-bold fs-5">
						<div class="mb-2">{{teachList[teachIndex].text}}</div>
						<div class="d-flex justify-content-center">
							<button type="button" class="btn btn-link text-muted me-2" @click="teachIndex>0&&(teachIndex--)">{{$t("app.lastStep")}}</button>
							<button v-if="teachIndex==teachList.length-1" type="button" class="btn btn-outline-light" @click="closeTeach">{{$t("app.finish")}}</button>
							<button v-else type="button" class="btn btn-outline-light" @click="teachIndex<teachList.length-1&&(teachIndex++)">{{$t("app.nextStep")}}</button>
						</div>
						<div class="text-center fs-6 pt-2">
							<div class="btn btn-link" @click="closeTeach">{{$t("app.passHelp")}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 弹出框 -->
			<div v-if="currModal" class="d-flex justify-content-center align-items-center position-absolute top-0 bottom-0 end-0 start-0 bg-dark bg-opacity-50 index-1051">
				<div class="d-flex flex-column rounded-3 overflow-hidden shadow mx-2 mx-sm-0">
					<div class="bg-navy d-flex justify-content-between py-2 px-3 text-light">
						<span>{{currModal}}</span>
						<i class="bi bi-x-octagon" @click="setCurrModal()"></i>
					</div>
					<modules :curr-modal="currModal" class="d-flex p-3 bg-blue" :style="{minHeight:currModal==$t('common.userRecords')?'40rem':''}"></modules>
				</div>
			</div>
		</div>
		<audio-player v-show="false" ref="audioPlayer" :isLoop="true" :muted="!audioSwitch.bgAudio" :audio-list="bgAudioList.map(elm => elm.url)" />
		<audio-player v-show="false" ref="tenAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audioSwitch.tenAudio" :audio-list="tenAudioList.map(elm => elm.url)" />
		<audio-player v-show="false" ref="startAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audioSwitch.startAudio" :audio-list="startAudioList.map(elm => elm.url)" />
        <audio-player v-show="false" ref="endAudioPlayer" :isLoop="true" :isAutoPlayNext="false" :muted="!audioSwitch.startAudio" :audio-list="endAudioList.map(elm => elm.url)" />			
	</div>
</template>
<script>
import navbar from "@/components/public/navbar.vue";
import modules from '@/components/public/modules.vue'
import {IS_DEBUG,RATIO,COLOR,LAYER_TYPE,COMMAND,GAME_ID,PEARL_CODE,PEARL_TYPE,GAME_ROW,LOGOUT_TYPE,MUTATION_TYPE,UPDATE_PWD_RESULT,SET_SOURCE_RESULT,WITHDRAW_RESULT,RESULT,NOTICE_TYPE,MARK_TYPE} from "@/assets/js/config.js"
import { fullScreen,getIP,guid } from '@/assets/js/common.js'
import { computed,nextTick } from '@vue/runtime-core';
import { useLayer } from '@/assets/js/useLayer';
import { getSystem,getDeviceType } from "@/assets/js/common.js";
export default {
  	components: { navbar,modules },
	data() {
		return {
			ws:null,//websocket连接协议
			viewScale:0,//宽度比高度
			notice:"",//公告内容
			wsMsgFn:null,//websocket接收消息处理函数
			wsMsgObj:{},//websocket接收消息处理函数对象
			columnList:[],//台面列表画布绘制列数(没办法，想不到idea，只能暂且放这里了)
			ip:{
				cip: '',
				cname: ''
			},
			teachIndex:0,//当前引导页下标
			isShowTeach:localStorage.getItem('isTeach')=='false'?false:true,//是否显示引导
			isFullscreen:false,//当前是否全屏
			chatWs:null,//用于全局保存客服功能的websocket连接
			hasChatMsg:false,//是否有未读的客服消息
			fastChatMsg:{
				serverName:"",
				msg:"",
			},//聊天快捷消息显示对象
			chatMsgTimer:null,//聊天快捷消息显示计时器对象
			callInfo:null,//通话信息
			roomCountdownWorker:null,//当前台倒计时计时器
			bgAudioList: [
                { url: require('@/assets/media/common/game_bg.mp3') },
                { url: require('@/assets/media/common/game_bg_1.mp3') },
                { url: require('@/assets/media/common/game_bg_2.mp3') },
                { url: require('@/assets/media/common/game_bg_3.mp3') },
                { url: require('@/assets/media/common/game_bg_4.mp3') }
            ],//背景音效列表
			tenAudioList:[
                { url:require('@/assets/media/common/time_ten_1.mp3') },
                { url:require('@/assets/media/common/time_ten_2.mp3') },
                { url:require('@/assets/media/common/time_ten_3.mp3') }
            ],//最后10秒音效
			startAudioList:[
                { url: require('@/assets/media/common/bet_start_1.mp3') },
                { url: require('@/assets/media/common/bet_start_2.mp3') },
                { url: require('@/assets/media/common/bet_start_3.mp3') },
                { url: require('@/assets/media/common/bet_start_4.mp3') },
                { url: require('@/assets/media/common/bet_start_5.mp3') },
                { url: require('@/assets/media/common/bet_start_6.mp3') },
            ],//开始音效
            endAudioList:[
                { url:require('@/assets/media/common/bet_stop.mp3') }
            ],//结束音效
			onlineWorker:null,//心跳线程
			goodWorker:null,//线路优劣线程
			broadcastHtml:"",//全局广播html
		};
	},
	provide() {
		return {
			notice: computed(()=>this.notice),//公告内容
		}
	},
	computed: {
		teachList(){//定义引导列表，放在属性中text值才能有响应式
			return [
				{
					text:this.$t("app.helpCustomer"),
					class:"teach teach-chat"
				},
				{
					text:this.$t("app.helpPersonal"),
					class:"teach teach-personal"
				},
				{
					text:this.$t("app.helpSetting"),
					class:"teach teach-settings"
				},
			]
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		roomInfo(){
			return this.$store.state.roomInfo;
		},
		videoLineList(){
            return this.$store.state.videoLineList;
        },
		currVideoLine(){
			return this.$store.state.currVideoLine;
		},
		skin(){
			return this.$store.state.skin;
		},
		roomList(){
            return this.$store.state.roomList;
        },
		audioSwitch(){
            return this.$store.state.audioSwitch;
        },
		currModal(){
			return this.$store.state.currModal;
		},
		//资金渠道列表
        fundChannel(){
            return this.$store.state.fundChannel;
        },
		isReConnWs(){
            return this.$store.state.isReConnWs;
        },
	},
	created() {
		this.roomCountdownWorker=new Worker("timeWorker.js");
		this.roomCountdownWorker.onmessage=(e)=>{
			e.data==0&&(this.$store.commit({
				type:MUTATION_TYPE.setRoomInfo,
				roomInfo:{
					countdown:this.roomInfo.countdown>0?this.roomInfo.countdown-1:0
				}
			}));
		}
	},
	mounted(){
		this.ip=getIP();
		addEventListener("resize",this.setViewSize());
		//监听设备纵横方向改变
		window.onorientationchange=this.showBestTip();
		//监听全屏改变
		addEventListener("fullscreenchange",()=>{
			if(document.fullscreenElement){
				this.isFullscreen=true;
			}else{
				this.isFullscreen=false;
			}
		})
	},
	unmounted() {
		removeEventListener("resize",this.setViewSize);
	},
	methods: {
		/**
		 * 设置全屏/取消全屏
		 */
		setFullscreen(){
			fullScreen().then(v=>{
				v==-1&&useLayer({str:this.$t("app.unFullscreen")});
			});
		},
		/**
		 * 横屏时显示全屏提示
		 */
		showBestTip(){
			if(!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement)){
				setTimeout(() => {
					if(window.orientation&&(window.orientation==90||window.orientation==-90)){//横屏
						// this.setFullscreen();
						window.fullScreen=this.setFullscreen;//设置window函数使点击全屏提示中的全屏按钮起作用
						useLayer({str:this.$t("app.isHorizontal")+this.$t("app.click")+"<a href='javascript:window.fullScreen()'>"+this.$t("login.fullscreen")+"</a>"+this.$t("app.better")});
					}
				});
			}
			return this.showBestTip;
		},
		/**
		 * 设置显示区大小
		 */
		setViewSize() {
			// this.viewScale=(innerHeight/innerWidth>1.6&&innerHeight/innerWidth<2.2)?innerHeight/innerWidth:1.8;
			let whScale=innerWidth/innerHeight;
			this.viewScale=(innerHeight/innerWidth>1.6&&innerHeight/innerWidth<2.2)?innerHeight/innerWidth:(whScale<1.6?1.6:(whScale>2.4?2.4:whScale));
			IS_DEBUG&&console.log("设置显示区大小");
			let width=innerWidth;
			let height=innerHeight;
			let elem=document.getElementById("wrapper");
			elem.removeAttribute("style");
			// if(width<height&&width<576){//竖屏显示
			if(
				(getDeviceType()=="h5_phone_4"&&(window.orientation==180||window.orientation==0))||
				(getDeviceType()=="h5_web_4"&&width<height&&width<576)
			){//竖屏显示(移动设备竖屏或者非移动设备但宽小于高且小于576)
				if (width*this.viewScale < height) {//宽度小了或者高度大了，不能增加宽度(因为宽度已达极限)，只能减小高度
				//重新配置高度，宽度为满宽度
					elem.style.width="100%";
					if(getDeviceType()=="h5_phone_4"&&(window.orientation==180||window.orientation==0)){//移动端竖屏
						elem.style.height="100%";
					}else{
						elem.style.height=width * this.viewScale+"px";
					}
				} else {//大于设定比例，说明宽度大了或者高度小了，不能增加高度(因为高度已达极限)，只能减小宽度
				//重新配置宽度，高度为满高度
					elem.style.height="100%";
					elem.style.width=height / this.viewScale+"px";
				}
				document.getElementsByTagName("html")[0].style.fontSize=(elem.clientHeight-1035)/1552+1+"rem";//以高度每1035尺寸1rem为标准
			}else{//横屏显示
				let style=getComputedStyle(document.getElementById("wrapper"));
				if(width/this.viewScale>height){//宽度大了或者高度小了，不能增加高度(因为高度已达极限)，只能减小宽度
					elem.style.width=height*this.viewScale+"px";
				}
				elem.style.height=style.width.replace('px','')/ this.viewScale+"px";//重新配置高度
				document.getElementsByTagName("html")[0].removeAttribute("style");
				document.getElementsByTagName("html")[0].style.fontSize=(elem.clientWidth-1320)/1700+0.9+"rem";//宽度超过1320的部分，每增加1980，字体增加1rem
			}
			return this.setViewSize;
		},
		/**
		 * 设置画布大小
		 * @param {HTMLElement} elem 画布DOM对象
		 */
		setCanvasSize(elem) {
			//将画布宽高设置为0，以免对获取其父元素宽高造成影响
            elem.width=0;
            elem.height=0;
            elem.style.width=0;
            elem.style.height=0;
			//获取父元素样式
			let parentStyle=getComputedStyle(elem.parentElement);
			let parentWidth=parentStyle.width.replace('px','');
			let parentHeight=parentStyle.height.replace('px','');
			//重新设置画布大小
			elem.width=RATIO*parentWidth;
			elem.height=RATIO*parentHeight;
			elem.style.width=parentWidth+"px";
			elem.style.height=parentHeight+"px";
		},
		/**
		 * 绘制画布表格
		 * @param {HTMLElement} canvasElem 要绘制的canvas集合
		 * @param {Number} row 要绘制的行数
		 * @param {bool} hasHead 是否有列头
		 * @param {Number} ratio 宽比高的值
		 */
		drawCanvasTable(canvasElem,row,hasHead,ratio){
			if(canvasElem){
				let obj=null;
				let canvas=canvasElem;
				let style=getComputedStyle(canvas);
				let width=RATIO*style.width.replace('px','');
				let height=RATIO*style.height.replace('px','');
				if (canvas.nodeName=="CANVAS"&&width>0&&height>0) {//限制宽高条件防止视口太小导致死循环
					let ctx=canvas.getContext("2d");
					let cellHeight=height/row;//单元格高度
					let column=Math.round(width / (cellHeight*(ratio?ratio:1)));
					let cellWidth = width / column;//获取单元格宽度
					ctx.clearRect(0, 0, width, height);//清除画布
					ctx.strokeStyle = '#b1b1b1';
					ctx.lineWidth = 1*RATIO;
					//绘制表格横线
					for (var j = 0; j < row; j++) {
						ctx.beginPath();
						ctx.moveTo(0, cellHeight * j);
						ctx.lineTo(cellWidth * column, cellHeight * j);
						ctx.stroke();
					}
					//绘制表格竖线
					for (let k = 0; k < column; k++) {
						ctx.beginPath();
						ctx.moveTo(cellWidth * k, 0);
						ctx.lineTo(cellWidth * k, height);
						ctx.stroke();
					}
					obj={
						elem:canvas,
						column:hasHead?--column:column,
						cellWidth,
						cellHeight
					}
				}
				return obj;
			}
		},
		/**
		 * websocket发送消息
		 */
		wsSend(){
			if(this.ws){
				let str = '';
				for (let i = 0; i < arguments.length; i++) {
					i != arguments.length - 1 && (str += arguments[i] + '|');
					i == arguments.length - 1 && (str += arguments[i] + ';');
				}
				this.ws.send(str);
				IS_DEBUG&&console.log(str);
			}
		},
		/**
		 * websocket接收消息(在登录页中绑定)
		 */
		onMessage(){
			if(this.ws){
				let invalid="";//无效字符串
				if(this.onlineWorker){
					this.onlineWorker.postMessage({number:0,ms:0});
					this.onlineWorker.terminate();
				}
				if(this.goodWorker){
					this.goodWorker.postMessage({number:0,ms:0});
					this.goodWorker.terminate();
				}
				this.onlineWorker=new Worker("timeWorker.js");//心跳线程
				this.goodWorker=new Worker("timeWorker.js");//线路优劣线程
				this.ws.onmessage=async (e)=>{
					IS_DEBUG&&e.data.indexOf(COMMAND.all_online)==-1&&console.log(e.data);
					//数据包处理
					let obj = this.setSocketData(e.data,invalid);
					let arr=obj.arr;
					invalid=obj.invalid;
					
					for (let i = 0; i < arr.length; i++) {
						let data = arr[i];
						IS_DEBUG&&e.data.indexOf(COMMAND.all_online)==-1&&console.log("转换后的数据："+JSON.stringify(data));
						!IS_DEBUG&&data.command.indexOf("hello")!=-1&&console.log(e.data);
						switch (data.command) {
							case COMMAND.all_online:
								this.goodWorker.postMessage({number:0,ms:0});//接收到online返回则关掉线路优劣定时器
								this.onlineWorker.postMessage({number:1,ms:20000});
								this.onlineWorker.onmessage=(onlineE)=>{
									if(onlineE.data==0){
										this.wsSend(COMMAND.all_online, this.userInfo.id, this.userInfo.guid);
										this.goodWorker.postMessage({number:1,ms:4000});
										this.goodWorker.onmessage=(goodE)=>{
											if(goodE.data==0){//4s内没有接收到online返回则提示线路较差
												useLayer({str:this.$t("app.lowline")});
											}
										}
									}
								}
								break;
							case COMMAND.all_logout:
								this.$store.commit({
									type:MUTATION_TYPE.setIsReConnWs,
									isReConnWs:false
								})
								this.ws&&this.ws.close();
								this.onlineWorker.terminate();
								this.goodWorker.terminate();
								this.$store.commit({
									type:MUTATION_TYPE.setCurrVideoLine,
									currVideoLine:""
								})
								this.$store.commit({
									type:MUTATION_TYPE.setUserInfo,
									userInfo:{
										guid:""
									}
								})
								if(data.type==LOGOUT_TYPE.exit){
									this.goPage("login");
								}else{
									let str="";
									if(data.type==LOGOUT_TYPE.system){
										str=this.$t("app.loginTop");
									}else if(data.type==LOGOUT_TYPE.more){
										str=this.$t("app.otherLogin");
									}else if(data.type==LOGOUT_TYPE.timeout){
										str=this.$t("app.getout");
									}
									useLayer({
										type:LAYER_TYPE.confirm,
										str,
										btn:[this.$t("app.toLogin")],
										yesFn:(i)=>{
											layer.close(i);
											this.goPage("login");
										}
									})
								}
								break;
							case COMMAND.all_getnotice:
								if(data.type==NOTICE_TYPE.normal){
									this.notice=data.str;//修改公告
								}else if(data.type==NOTICE_TYPE.broadcast){
									this.broadcastHtml='<div class="text-light text-nowrap my-1">'+
															'<span>恭喜玩家</span>'+
															'<span class="text-yellow fw-bold fs-5">【'+data.info.userid+'】</span>'+
															'<span>集齐印记</span>'+
															'<span class="text-primary fw-bold fs-5">【'+(data.info.mode==MARK_TYPE.animal?'十二生肖':data.info.mode==MARK_TYPE.monk?'十八罗汉':'二十四节气')+'】</span>'+
															'<span>获得返利</span>'+
															'<span class="text-danger fw-bold fs-5">【'+data.info.score+'】</span>'+
														'</div>';
								}
								break;
							case COMMAND.all_roomstatechange:
								let stateChangeRoom=this.roomList.find(v=>v.id==data.roomid);//找到变更状态的台
								stateChangeRoom&&(stateChangeRoom.rstate=data.state);//修改状态
								break;
							case COMMAND.all_workerupdate:
								let workerUpdateRoom=this.roomList.find(v=>v.id==data.table);//找到变更工作人员的台
								if(workerUpdateRoom){//修改照片、姓名
									workerUpdateRoom.workerimg=data.img;
									workerUpdateRoom.worker=data.name;
								}
								break;
							// case COMMAND.all_changeboot:
							// 	let changeBootRoom=this.roomList.find(v=>v.id==data.id);
							// 	if(changeBootRoom){//找到换靴台
							// 		//清空路单
							// 		this.pearlObj={
							// 			id:data.id,
							// 			pearl:[]
							// 		}
							// 		//更新靴局
							// 		changeBootRoom.boot=data.boot;
							// 		changeBootRoom.innings=data.innings;
							// 		//更新统计
							// 		this.countList[data.id]=[0,0,0];
							// 	}
							// 	break;
							case COMMAND.all_getroomlist:
								if(data.game==GAME_ID.tiger){
									data.list=[
										{
											"id":-1,"game":GAME_ID.tiger,"rid":guid(),"room":"水果幸运机","table":guid(),
											"boot":"1","innings":"1","currnum":"0","antes":"0","carry":"0","type":"0",
											"ismy":"0","countdown":"0","second":"0","worker":"","workerimg":"","isar":"0",
											"tablelimit":"","rstate":"0","bgcolor":""
										}
									];
									// if(IS_DEBUG||(!IS_DEBUG&&this.userInfo.id=="sh002")){
										data.list.push({
											"id":-2,"game":GAME_ID.tiger,"rid":guid(),"room":"招财进宝","table":guid(),
											"boot":"1","innings":"1","currnum":"0","antes":"0","carry":"0","type":"0",
											"ismy":"0","countdown":"0","second":"0","worker":"","workerimg":"","isar":"0",
											"tablelimit":"","rstate":"0","bgcolor":""
										})
									// }
								}
								this.$store.commit({
									type:MUTATION_TYPE.setRoomList,
									roomList:data.list
								})
								for (let item of data.list) {
									this.countdown(item.id,item.countdown);
								}
								break;
							case COMMAND.all_getbankrollway:
								if(data.type==1){//充值
									this.$store.commit({
										type:MUTATION_TYPE.setRechargeFundsList,
										rechargeFundsList:data.list
									})
								}else if(data.type==2){//提现
									this.$store.commit({
										type:MUTATION_TYPE.setWithdrawFundsList,
										withdrawFundsList:data.list
									})
								}
								break;
							case COMMAND.all_getcoin:
								this.$store.commit({
									type:MUTATION_TYPE.setRechargeCurrencyList,
									rechargeCurrencyList:data.list
								})
								break;
							case COMMAND.all_getuserchannel:
								this.$store.commit({
									type:MUTATION_TYPE.setFundChannel,
									fundChannel:{
										alipay:data.alipay,
										bank:data.bank,
										coin:data.coin
									}
								})
								break;
							case COMMAND.all_getwithdrawlimit:
								this.$store.commit({
									type:MUTATION_TYPE.setWithdrawLimit,
									withdrawLimit:{
										min:data.min,
										max:data.max
									}
								})
								break;
							case COMMAND.all_getshareurl:
								this.$store.commit({
									type:MUTATION_TYPE.setShareQrStr,
									shareQrStr:data.url
								})
								break;
							case COMMAND.all_getquestion:
								this.$store.commit({
									type:MUTATION_TYPE.setProblemList,
									problemList:data.list.reverse()
								})
								break;
							case COMMAND.all_addquestion:
								if(data.result>0){
									this.wsSend(COMMAND.all_getquestion,1,100,this.userInfo.id,this.userInfo.guid);
								}else{
									useLayer({str:this.$t("personal.sendErr")});
								}
								break;
							case COMMAND.all_updatepwd:
								var r=data.result;
								if(r==UPDATE_PWD_RESULT.success){
									useLayer({
										type:LAYER_TYPE.confirm,
										str:this.$t("personal.updLoginPwdSuccess"),
										btn:[this.$t("common.ok")],
										yesFn:(i)=>{
											window.location.reload();
										}
									})
								}else if(r==UPDATE_PWD_RESULT.pwdEmpty){
									useLayer({str:this.$t("personal.pwdEmpty")});
								}else if(r==UPDATE_PWD_RESULT.pwdErr){
									useLayer({str:this.$t("personal.pwdErr")});
								}else if(r==UPDATE_PWD_RESULT.userErr){
									useLayer({str:this.$t("personal.invalidUser")});
								}else if(r==UPDATE_PWD_RESULT.pwdEqual){
									useLayer({str:this.$t("personal.pwdEqual")});
								}
								break;
							case COMMAND.all_setuserchannel:
								var r=data.result;
								if(r==SET_SOURCE_RESULT.error){
									useLayer({str:this.$t("common.subErr")});
								}else if(r==SET_SOURCE_RESULT.cannot){
									useLayer({str:this.$t("personal.accountLimit")});
								}else if(r==SET_SOURCE_RESULT.noCurrency){
									useLayer({str:this.$t("personal.invalidCurrency")});
								}else{
									this.wsSend(COMMAND.all_getuserchannel,"list",this.userInfo.id,this.userInfo.guid);
									useLayer({str:this.$t("common.subSuccess")});
								}
								break;
							case COMMAND.all_deluserchannel:
								var r=data.result;
								if(r==SET_SOURCE_RESULT.error){
									useLayer({str:this.$t("common.subErr")});
								}else if(r==SET_SOURCE_RESULT.cannot){
									useLayer({str:this.$t("personal.sourceHasBills")});
								}else if(r==SET_SOURCE_RESULT.noCurrency){
									useLayer({str:this.$t("personal.invalidCurrency")});
								}else{
									let key=Object.keys(this.fundChannel).find(v=>this.fundChannel[v].find(item=>item.id==r));
									this.$store.commit({
										type:MUTATION_TYPE.setFundChannel,
										fundChannel:{
											[key]:this.fundChannel[key].filter(v=>v.id!=data.result)
										}
									})
									useLayer({str:this.$t("common.subSuccess")});
								}
								break;
							case COMMAND.all_withdraw:
								var r=data.result;//提交结果 
								if(r==WITHDRAW_RESULT.noUser){
									useLayer({str:this.$t("personal.invalidUser")});
								}else if(r==WITHDRAW_RESULT.pwdErr){
									useLayer({str:this.$t("personal.withdrawPwdErr")});
								}else if(r==WITHDRAW_RESULT.moneyLack){
									useLayer({str:this.$t("personal.withdrawAmountLack")});
								}else if(r==WITHDRAW_RESULT.moneyErr){
									useLayer({str:this.$t("personal.withdrawAmountErr")});
								}else if(r==WITHDRAW_RESULT.reportErr){
									useLayer({str:this.$t("personal.reportErr")});
								}else if(r==WITHDRAW_RESULT.acceptedErr){
									useLayer({str:this.$t("personal.acceptedErr")});
								}else if(r==WITHDRAW_RESULT.systemErr){
									useLayer({str:this.$t("common.service")});
								}else if(!r||r==WITHDRAW_RESULT.error){
									useLayer({str:this.$t("personal.withdrawErr")});
								}else{
									useLayer({str:this.$t("personal.withdrawSuccess")});
									this.setCurrModal();
									this.wsSend(COMMAND.all_getuserinfo,this.userInfo.id, this.userInfo.guid);//获取用户信息
								}
								break;
							case COMMAND.all_getuserinfo:
								this.$store.commit({
									type:MUTATION_TYPE.setUserInfo,
									userInfo:{
										realCoin:data.realmoney,
										esportsCoin:data.fightmoney,
										mark:data.mark,
										markType:data.marktype
									}
								})
								break;
							case COMMAND.all_getrecharge:
								this.$store.commit({
									type:MUTATION_TYPE.setRechargeLog,
									rechargeLog:data.list
								})
								break;
							case COMMAND.all_getwithdraw:
								this.$store.commit({
									type:MUTATION_TYPE.setWithdrawLog,
									withdrawLog:data.list
								})
								break;
							case COMMAND.all_getbetlog:
								this.$store.commit({
									type:MUTATION_TYPE.setSubmitLog,
									submitLog:data.list
								})
								break;
							case COMMAND.all_startgame:
								if(data.id==this.roomInfo.id){
									this.$store.commit({
										type:MUTATION_TYPE.setRoomInfo,
										roomInfo:{
											boot:data.boot,
											innings:data.innings,
											countdown:data.countdown
										}
									})
									this.$refs.startAudioPlayer.playNext();
								}
								if(this.roomList){
									let item=this.roomList.find(v=>v.id==data.id);//在台面列表中找到与当前开始的游戏匹配的项
									if(typeof item!="undefined"){//台面存在且可见
										if(item.id==data.id){//当前开始计时的游戏存在于台面列表中
											this.$store.commit({
												type:MUTATION_TYPE.setRoomList,
												room:{
													id:data.id,
													countdown:data.countdown,
													boot:data.boot,
													innings:data.innings,
													workerimg:data.workerimg,
													worker:data.worker
												}
											})
											this.countdown(data.id,data.countdown);
										}
									}
								}
								break;
							case COMMAND.all_endgame:
								if(data.id==this.roomInfo.id){
									this.$store.commit({
										type:MUTATION_TYPE.setRoomInfo,
										roomInfo:{
											countdown:0
										}
									})
									this.$refs.endAudioPlayer.playNext();
								}
								if(this.roomList){
									let room=this.roomList.find(v=>v.id==data.id);
									if(room&&room.countdown!=0){//找到结束的台并且台计时不是0则置为0
										this.$store.commit({
											type:MUTATION_TYPE.setRoomList,
											room:{
												id:data.id,
												countdown:0
											}
										})
									}
								}
								break;
							case COMMAND.all_entertable:
								let roomItem=this.roomList.find(v=>v.id==data.id);
								if(data.result==RESULT.enterTable.unmoney){
									useLayer({str:this.$t("common.unMoney")});
									return;
								}else if(data.result==RESULT.enterTable.userfull){
									useLayer({str:this.$t("common.userFull")});
									return;
								}else if(data.result!=RESULT.enterTable.success){
									useLayer({str:this.$t("home.goRoomErr")});
									return;
								}
								if(roomItem){
									//有路的视频一般有视频，需要设置视频线路
									if(this.hasPearl(roomItem.game)){
										//有线路列表并且当前没有选择视频线路时，自动配置默认线路
										if(this.videoLineList){
											this.setCurrVideoLine(this.getDefaultLineId(data.rid));
										}
									}
								}
								break;
							case COMMAND.all_settlement:
								// if(!this.roomInfo.id){//在大厅
									this.wsSend(COMMAND.all_getuserinfo,this.userInfo.id, this.userInfo.guid);//获取用户信息
									// this.wsSend(COMMAND.all_getgain,data.game,data.id,data.boot,data.innings,this.userInfo.id,this.userInfo.guid);//有提交过才需要获取输赢
								// }
								break;
							case COMMAND.all_getgain:
								this.$store.commit({
									type:MUTATION_TYPE.setUserInfo,
									userInfo:{
										realCoin:data.realmoney,
										mark:data.mark
									}
								})
								break;
							case COMMAND.all_tableupdate:
								if(this.roomList&&this.roomList.find(v=>v.id==data.tableid)){
									this.$store.commit({
										type:MUTATION_TYPE.setRoomList,
										room:{
											id:data.tableid,
											currnum:data.usercount
										}
									})
								}
								break;
						}
						
						// 模拟收到推送
						// if(data.command=="slot_getlimitred"){
						// 	setTimeout(() => {
						// 		// this.wsMsgFn({"command":"slot_submitbet","result":[[[12,1,2],[6,7,8],[5,6,7],[3,4,5],[1,2,3]]],"score":[30]});
						// 		this.wsMsgFn({"command":"slot_submitbet","result":[[[9,10,11],[13,14,15],[16,17,1],[12,13,14],[12,13,14]]],"score":[0]});
						// 	}, 1000);
						// }
						// if(data.command=="texa_getpool"){
						// 	setTimeout(() => {
						// 		this.wsMsgFn({"command":"all_willstartgame","isready":"1","countdown":"10"});
						// 		this.wsMsgFn({"command":"all_readygame","id":"a11","state":1,"loc":2});
						// 	}, 1000);
						// }
						
						this.wsMsgFn&&this.wsMsgFn(data);
						for (let key in this.wsMsgObj) {
							this.wsMsgObj[key](data);
						}
						await nextTick();//加上这个以免因连包导致循环赋值，但因为vue的机制使循环最后一次才能监听到
					}
				}
			}
		},
		/**
         * 处理websocket接收的数据
         */
		setSocketData(str,invalid){
            //数据包处理
            let arr=[];//存放接收到的数据
            try {
                let obj1 = JSON.parse(str);//尝试转换接收到的数据
                arr.push(obj1);//成功则加入数组作为一条有效数据
            } catch (error) {
                while (str.indexOf("}{")!=-1) {//连包
                    IS_DEBUG&&console.log("有连包");
                    let part=str.substring(0,str.indexOf("}{")+1);//截取背向花括号前的片段
                    str = str.replace(part,"");
                    try {
                        let obj2 =JSON.parse(part);//尝试转换截取出来的片段
                        arr.push(obj2);//成功则加入数组作为一条有效数据
                    } catch (error) {//失败说明是不完整数据
                        invalid+=part;//与上一次的无效数据拼一起
                        try {
                            let obj3=JSON.parse(invalid);//尝试转换拼接后的字符串
                            arr.push(obj3);//成功则加入数组作为一条有效数据
                            invalid="";//并且清空无效字符串
                        } catch (error) {}//不成功不理会
                    }
                }
                //走到这里说明没有连包了
                try {
                    let obj4=JSON.parse(str);//尝试转换剩余的片段
                    arr.push(obj4);//成功则加入数组作为一条有效数据
                } catch (error) {//失败则说明是不完整数据
                    IS_DEBUG&&console.log("有分包");
                    invalid+=str;//与上一次的无效数据拼一起
                    try {
                        let obj5=JSON.parse(invalid);//尝试转换拼接后的字符串
                        arr.push(obj5);//成功则加入数组作为一条有效数据
                        invalid="";//并且清空无效字符串
                    } catch (error) {}//不成功则不理会，等待下一次接收数据再处理就好
                }
            }
            return {
				arr,invalid
			};
        },
		/**
		 * 绘制台面列表和台面共有的路单
		 * @param {Object} elemObj 使用drawCanvasTable绘制画布表格返回的对象
		 * @param {Array} arr 路单数据
		 * @param {PEARL_TYPE} type 路单类型
		 * @param {GAME_ID} game 游戏
		 * @param {Bool} isScale 小路是否放大，用于查看全部
		 */
		drawPearl(elemObj,arr,type,game,isScale){
			let pearlArr=JSON.parse(JSON.stringify(arr));
			let cellHeight=elemObj.cellHeight;//单元格高度
			let cellWidth = elemObj.cellWidth;//单元格宽度
			let ctx=elemObj.elem.getContext("2d");
			ctx.textBaseline = 'middle';
			ctx.font = 'bold ' + cellWidth * .5 + 'px SimHei';
			if([GAME_ID.bac,GAME_ID.lh,GAME_ID.longfong,GAME_ID.coin].indexOf(Number(game))!=-1){
				//bac
				let zCode = PEARL_CODE.Z;
				let xCode = PEARL_CODE.X;
				let hCode = PEARL_CODE.H;
				let zdCode=PEARL_CODE.ZD;
				let xdCode=PEARL_CODE.XD;
				//longfong
				if(game==GAME_ID.longfong){
					zCode = PEARL_CODE.L;
					xCode = PEARL_CODE.F;
					hCode = PEARL_CODE.HE;
					zdCode=PEARL_CODE.LSG;
					xdCode=PEARL_CODE.FSG;
				}
				let radius=2.6;//半径比例
				// if(type!=PEARL_TYPE.big_hollow){//小路需要按照原始格子的高度去计算，否则会造成计算的列数出错
				// 	column=Math.round(width / (cellHeight*2))*2;
				// }
				let maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
				let lastPoint = 0;//保存上一次最后一行露珠的最大坐标点，用来比较是否可以左拐
				let currentRow;//保存当前列已达到的行数
				if((type==PEARL_TYPE.small_solid||type==PEARL_TYPE.small_hollow||type==PEARL_TYPE.bevel)&&!isScale){//小路的单元格为正常单元格的一半
					cellHeight/=2;
					cellWidth/=2;
				}
				for (let i = 0; i < pearlArr.length; i++) {
					currentRow=0;
					for (let j = 0; j < pearlArr[i].length; j++) {//currentRow为当前列的露珠已到达的行数
						//设置边框粗细
						if(type == PEARL_TYPE.big_hollow){
							ctx.lineWidth = cellWidth * .12;
						}else{
							ctx.lineWidth = cellWidth * .15;
						}
						//设置圆圈位置
						let x = i * cellWidth + (cellWidth / 2);//正常向下的x值
						let y = j * cellHeight + (cellHeight / 2);//正常向下的y值
						if (currentRow == 6 || x <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
							if (currentRow == 6 && lastPoint < x - (pearlArr[i].length - 6) * cellWidth) {//如果当前已到达第6行并且第6行露珠的x坐标大于该行最大坐标的8倍，即前面有7个空位，则向左拐
								x = (i - ((j + 1) - currentRow)) * cellWidth + (cellWidth / 2);//当前列数-
							} else {//否则右拐
								x = (i + j - currentRow + 1) * cellWidth + (cellWidth / 2);//当前个数-当前所在行+当前列=要画的露珠列
								maxPoint[currentRow - 1] = x;//更新最大坐标点
							}
							y = (currentRow - 1) * cellHeight + (cellHeight / 2);
						} else {//否则向下画
							currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? cellWidth / -2 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
							maxPoint[currentRow] = x;//更新最大坐标点
							currentRow++;//累加当前列露珠到达的行数
						}
						//设置圆圈颜色
						let letter = pearlArr[i][j].substr(0, 1);//获取首字母
						if (xCode.indexOf(letter)!=-1) {
							ctx.fillStyle = ctx.strokeStyle = ([GAME_ID.bac,GAME_ID.lh,GAME_ID.coin].indexOf(Number(game))!=-1?COLOR.blue:COLOR.red);//填充颜色
						} else if (zCode.indexOf(letter)!=-1) {
							ctx.fillStyle = ctx.strokeStyle = ([GAME_ID.bac,GAME_ID.lh,GAME_ID.coin].indexOf(Number(game))!=-1?COLOR.red:COLOR.blue);
						} else if (hCode.indexOf(letter)!=-1) {
							ctx.fillStyle = ctx.strokeStyle = COLOR.green;
						}
						//绘制圆圈/斜线
						ctx.beginPath();
						if (type == PEARL_TYPE.bevel) {
							ctx.moveTo(x - cellWidth / radius , y + cellHeight / radius );
							ctx.lineTo(x + cellWidth / radius , y - cellHeight / radius );
						} else {
							ctx.arc(x, y, cellHeight / radius, 0, 2 * Math.PI);
						}
						if(type==PEARL_TYPE.small_solid){
							ctx.fill();
						}else{
							ctx.stroke();
						}
						if(type==PEARL_TYPE.big_hollow){
							//绘制斜线和数字
							if (([GAME_ID.bac,GAME_ID.lh,GAME_ID.coin].indexOf(Number(game))!=-1&&pearlArr[i][j].length >= 3)||(game==GAME_ID.longfong&&pearlArr[i][j].length >=2)) {//当前遍历到的最后一项并且字符串长度为3
								var text = pearlArr[i][j].substr(-1);//截取最后一位
								// var text = pearlArr[i][j].substr(2,1);//截取第三位
								// game==GAME_ID.longfong&&(text=pearlArr[i][j].substr(1,1));//截取第二位
								if(text!="0"){//有需要显示“和”统计
									ctx.fillText(text, x - ctx.measureText(text).width / 2, y);
									ctx.lineWidth = cellWidth * .07;//边框粗细
									ctx.moveTo(x, y + cellHeight / radius);
									ctx.lineTo(x + cellHeight / radius, y);
									ctx.stroke();
								}
							}
							//填充圆点
							if (zdCode.indexOf(letter)!=-1) {//含有庄对
								ctx.fillStyle = ([GAME_ID.bac,GAME_ID.lh,GAME_ID.coin].indexOf(Number(game))!=-1?COLOR.red:COLOR.blue);
								ctx.beginPath();
								ctx.arc(x - cellWidth / 3.5, y - cellHeight / 3.5, cellWidth / radius / 3.5, 0, 2 * Math.PI);
								ctx.fill();
							}
							if (xdCode.indexOf(letter)!=-1) {//含有闲对
								ctx.fillStyle = ([GAME_ID.bac,GAME_ID.lh,GAME_ID.coin].indexOf(Number(game))!=-1?COLOR.blue:COLOR.red);
								ctx.beginPath();
								ctx.arc(x + cellWidth / 3.5, y - cellHeight / 3.5, cellWidth / radius / 3.5, 0, 2 * Math.PI);
								ctx.fill();
							}
						}
					}
				}
			}else if(game==GAME_ID.cor){
				ctx.font = 'bold ' + cellWidth * .3 + 'px SimHei';
				//绘制列头
				ctx.fillStyle = COLOR.red;
				ctx.fillRect(0, 0, cellWidth, cellHeight);
				ctx.fillStyle = COLOR.blue;
				for (let i = 0; i < 3; i++) {
					ctx.fillRect(0, cellHeight + (i * cellHeight), cellWidth, cellHeight);
				}
				ctx.fillStyle = "#fff";
				for (let i = 0, arr = [this.$t("common.z"), this.$t("common.x")+'1', this.$t("common.x")+'2', this.$t("common.x")+'3']; i < arr.length; i++) {
					ctx.fillText(arr[i], cellWidth / 2 - ctx.measureText(arr[i]).width / 2, (i * cellHeight) + cellHeight / 2);
				}
				//绘制路单
				for (let i = 0; i < pearlArr.length; i++) {
					for (let j = 0; j < pearlArr[i].length; j++) {
						// 创建渐变
						let grd=ctx.createRadialGradient(
							(i + 1) * cellWidth + cellWidth / 2,
							j * cellHeight + cellHeight / 2,
							cellWidth / 2.4,
							(i + 1) * cellWidth + cellWidth / 2-(cellWidth / 2.4/2),//x坐标减去二分之一半径
							j * cellHeight + cellHeight / 2-(cellWidth / 2.4/2),
							0);
						//填充圆
						let color=COLOR.red;//设置填充颜色(红色)
						if (j > 0) {//轮到闲
							if(pearlArr[i][j] < 0){
								color = '#848484';//输填充灰色
							}else{
								if(pearlArr[i][j]==40){//炸弹填充黑色
									color=COLOR.black;
								}else if(pearlArr[i][j]==30){//金牛填充金色
									color='#f2c056';
								}else if(pearlArr[i][j]==20){//银牛填充银色
									color='#c0c0c0';
								}else{//赢填充蓝色
									color = COLOR.blue;
								}
							}
						}
						grd.addColorStop(0,color);
						grd.addColorStop(1,COLOR.ray);
						ctx.fillStyle = color;
						ctx.strokeStyle=color;
						ctx.beginPath();
						// ctx.arc((i + 1) * cellWidth + cellWidth / 2, j * cellHeight + cellHeight / 2, cellWidth / 2.4, 0, 2 * Math.PI);//除2.4才不会刚好跟单元格一样大
						// ctx.roundRect((i + 1) * cellWidth+cellWidth /8,j * cellHeight + cellHeight /3.8,cellWidth/1.3,cellHeight/1.5,[5,10]);//画圆角矩形
						ctx.roundRect((i + 1) * cellWidth+cellWidth /8,j * cellHeight + cellHeight /6,cellWidth/1.3,cellHeight/1.5,[0,RATIO*5]);//画圆角矩形
						ctx.fill();
						// ctx.beginPath();
						// ctx.arc((i + 1) * cellWidth + cellWidth / 2, j * cellHeight + cellHeight / 2,cellHeight/1.5/2, Math.PI,Math.PI*3/2,false);
						ctx.stroke();
						//填充点数
						ctx.fillStyle = '#fff';
						let text = Math.abs(pearlArr[i][j]);
						if (text == '10') {
							text = this.$t("app.great")+this.$t("app.great");
						} else if (text == '20') {
							text = this.$t("room.resultSilver");
						} else if (text == '30') {
							text = this.$t("room.resultGold");
						} else if (text == '40') {
							text = this.$t("room.resultBomb");
						} else if (text == '11') {
							text = this.$t("app.nothing");
						}else{
							text=this.$t("app.great")+text;
						}
						// ctx.fillText(text, (i + 1) * cellWidth + cellWidth / 2 - ctx.measureText(text).width / 2, j * cellHeight + cellHeight / 1.7);
						ctx.fillText(text, (i + 1) * cellWidth + cellWidth / 2 - ctx.measureText(text).width / 2, j * cellHeight + cellHeight / 2);
					}
				}
			}else if(game==GAME_ID.dice){
				//绘制列头
				ctx.fillStyle = COLOR.red;
				ctx.fillRect(0, 0, cellWidth, cellHeight);
				ctx.fillStyle = COLOR.blue;
				for (let i = 0; i < 3; i++) {
					ctx.fillRect(0, cellHeight + (i * cellHeight), cellWidth, cellHeight);
				}
				ctx.fillStyle = "#fff";
				for (let i = 0, arr = [this.$t("room.bigSmall"), this.$t("room.oddEven"), this.$t("room.sumCount"), this.$t("room.eachNum")]; i < arr.length; i++) {
					ctx.fillText(arr[i], cellWidth / 2 - ctx.measureText(arr[i]).width / 2, (i * cellHeight) + cellHeight / 2);
				}
				//绘制路单
				for (let i = 0; i < pearlArr.length; i++) {
					let sum = pearlArr[i][0]+pearlArr[i][1]+pearlArr[i][2];//总和
					let text=['',sum % 2 == 0 ? this.$t("common.even") : this.$t("common.odd"),sum,[pearlArr[i][0],pearlArr[i][1],pearlArr[i][2]].join('')];//路单文本
					//设置颜色
					let color=COLOR.red;//设置填充颜色(红色)
					if(pearlArr[i][0]==pearlArr[i][1]&&pearlArr[i][1]==pearlArr[i][2]){//围
						color=COLOR.green;
						text[0]=this.$t("common.equal");
					}else if(sum>=4&&sum<=10){//小
						color=COLOR.blue;
						text[0]=this.$t("common.small");
					}else if(sum>=11&&sum<=17){//大
						color=COLOR.red;
						text[0]=this.$t("common.big");
					}
					for (let j = 0; j < Object.keys(GAME_ROW).find(key=>GAME_ROW[key].indexOf(GAME_ID.dice)!=-1); j++) {
						// 创建渐变
						let grd=ctx.createRadialGradient(
							(i + 1) * cellWidth + cellWidth / 2,
							j * cellHeight + cellHeight / 2,
							cellWidth / 2.4,
							(i + 1) * cellWidth + cellWidth / 2-(cellWidth / 2.4/2),//x坐标减去二分之一半径
							j * cellHeight + cellHeight / 2-(cellWidth / 2.4/2),
							0);
						//填充圆
						grd.addColorStop(0,color);
						grd.addColorStop(1,COLOR.ray);
						ctx.fillStyle = grd;
						ctx.beginPath();
						ctx.arc((i + 1) * cellWidth + cellWidth / 2, j * cellHeight + cellHeight / 2, cellWidth / 2.4, 0, 2 * Math.PI);//除2.4才不会刚好跟单元格一样大
						ctx.fill();
						//填充字
						ctx.fillStyle = '#fff';
						ctx.fillText(text[j], (i + 1) * cellWidth + cellWidth / 2 - ctx.measureText(text[j]).width / 2, j * cellHeight + cellHeight / 2);
					}
				}
			}else if(game==GAME_ID.rou){
				let redCode=PEARL_CODE.red;
				let blackCode=PEARL_CODE.black;
				let radius=2.4;//半径比例
				let maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
				let lastPoint = 0;//保存上一次最后一行露珠的最大坐标点，用来比较是否可以左拐
				let currentRow;//保存当前列已达到的行数
				for (let i = 0; i < pearlArr.length; i++) {
					currentRow=0;
					for (let j = 0; j < pearlArr[i].length; j++) {//currentRow为当前列的露珠已到达的行数
						let color=COLOR.green;
						//设置边框粗细
						ctx.lineWidth = cellWidth * .12;
						//设置圆圈颜色
						let letter = pearlArr[i][j];//获取数字
						if (blackCode.indexOf(letter)!=-1) {
							color = COLOR.black;//填充颜色
						} else if (redCode.indexOf(letter)!=-1) {
							color = COLOR.red;
						}
						ctx.fillStyle =color;
						ctx.strokeStyle =color;
						//设置圆圈位置
						let x = i * cellWidth + (cellWidth / 2);//正常向下的x值
						let y = j * cellHeight + (cellHeight / 2);//正常向下的y值
						if (currentRow == 6 || x <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
							if (currentRow == 6 && lastPoint < x - (pearlArr[i].length - 6) * cellWidth) {//如果当前已到达第6行并且第6行露珠的x坐标大于该行最大坐标的8倍，即前面有7个空位，则向左拐
								x = (i - ((j + 1) - currentRow)) * cellWidth + (cellWidth / 2);//当前列数-
							} else {//否则右拐
								x = (i + j - currentRow + 1) * cellWidth + (cellWidth / 2);//当前个数-当前所在行+当前列=要画的露珠列
								maxPoint[currentRow - 1] = x;//更新最大坐标点
							}
							y = (currentRow - 1) * cellHeight + (cellHeight / 2);
						} else {//否则向下画
							currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? cellWidth / -2 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
							maxPoint[currentRow] = x;//更新最大坐标点
							currentRow++;//累加当前列露珠到达的行数
						}
						// 创建渐变
						let grd=ctx.createRadialGradient(x,y,cellWidth / 2.4,
							x-(cellWidth / 2.4/2),//x坐标减去二分之一半径
							y-(cellWidth / 2.4/2),
							0);
						grd.addColorStop(0,color);
						grd.addColorStop(1,COLOR.ray);
						ctx.fillStyle = grd;
						//绘制圆圈/斜线
						ctx.beginPath();
						ctx.arc(x, y, cellHeight / radius, 0, 2 * Math.PI);
						ctx.fill();
						//填充字
						ctx.fillStyle = '#fff';
						ctx.fillText(letter, x - ctx.measureText(letter).width / 2, y);
					}
				}
			}
		},
		/**
		 * 处理拐路数据
		 * @param {Boolean} isHold 末列的和是否保留
		 */
		setPearlData(game,arr,column,isHold){
			//bac
			var arrZ = PEARL_CODE.Z;
			var arrX = PEARL_CODE.X;
			var arrH = PEARL_CODE.H;
			//longfong
			if(game==GAME_ID.longfong){
				arrZ=PEARL_CODE.L;
				arrX=PEARL_CODE.F;
				arrH=PEARL_CODE.HE;
			}
			var arr1 = [];//外层数组
			var letter = '';//首字母
			var count = 0;//和计数器
			if(game==GAME_ID.bac||game==GAME_ID.lh||game==GAME_ID.longfong||game==GAME_ID.coin){
				for (var i = arr.length-1; i >= 0; i--) {//数据为从旧到新，所以需要从后往前遍历
					if (arr1.length < column) {//未达到需要的列数
						letter = arr[i][0].substr(0, 1);//取内层数组第一项首字母
						if (arrH.indexOf(letter)!=-1) {//是和
							if (i == arr.length - 1 && isHold) {//如果是和的前提下并且已经遍历到最后一项
								arr1[arr1.length] = [];
								arr1[arr1.length - 1].unshift(arr[i][0]);//只压入第一项和
								arr[i].length - 1 != 0 && (arr1[arr1.length - 1][0] = arr1[arr1.length - 1][0] + (arr[i].length - 1));
							} else {//不是末列
								count = arr[i].length;
								continue;
							}
						} else {//不是和
							if (count != 0) {//有统计了和
								var arr2 = [];
								for (var j = 0; j < arr[i].length - 1; j++) {//遍历并添加除了最后一项
									arr2.push(arr[i][j]);
								}
								arr2.push(arr[i][arr[i].length - 1] + count);//将统计值拼接在当前遍历到的数组的最后一项
								if (
									arr1.length > 0 && (
									(arrZ.indexOf(arr[i][0].substr(0, 1)) != -1 && arrZ.indexOf(arr1[0][0].substr(0, 1)) != -1) ||
									(arrX.indexOf(arr[i][0].substr(0, 1)) != -1 && arrX.indexOf(arr1[0][0].substr(0, 1)) != -1)
									)) {//如果当前项之前已有项并且当前项与‘和’之前的项一样为庄类或者一样为闲类，则合并到一起
									var delArr = arr1.splice(0, 1);//删掉原有的拼接好的数组中的最后一项
									arr2 = arr2.concat(delArr[0]);//并拼接到当前项的最后
								}
								arr1.unshift(arr2);
								count = 0;
							} else {//还没有统计和
								arr1.unshift(arr[i].slice());
							}
						}
					} else {
						break;
					}
				}
			}else{
				arr1=arr;
			}
			//再次循环用于验证是否需要因右拐超出画布而移除最前面部分列
			// let columnCount=0;//拐弯后的总列数
			var maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
			var lastPoint = 0;
			for (var i = 0; i <arr1.length; i++) {//倒序遍历处理后的数组
				for (var j = 0, currentRow = 0; j < arr1[i].length; j++) {
					if (currentRow == 6 || (i+1) <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
						if (!(currentRow == 6 && lastPoint < (i+1) - (arr1[i].length - 6))) {//如果非(当前已到达第6行并且向左拐不与当前行最大点重叠)，则向右拐
							maxPoint[currentRow - 1] = (i+1) + (j - (currentRow-1));//更新最大坐标点为当前个数-当前所在行+当前列=要画的露珠列
						}
					} else {//否则向下画
						currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? 0 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
						maxPoint[currentRow] = i+1;//更新最大坐标点
						currentRow++;//累加当前列露珠到达的行数
					}
				}
			}
			for (let i = 0; i < maxPoint.length; i++) {
				if(maxPoint[i]>column){//只要有坐标点大于列数，即为超出画布
					arr1.splice(0,maxPoint[i]-column);//超过多少则移除前面多少列
					break;
				}
			}
			return arr1;
		},
		/**
		 * 设置当前视频线路id
		 */
		setCurrVideoLine(videoLineId){
			localStorage.setItem("videoline",videoLineId);
			this.$store.commit({
				type:MUTATION_TYPE.setCurrVideoLine,
				currVideoLine:videoLineId
			})
			useLayer({str:"已设置视频线路为【"+this.videoLineList.find(v=>v.id==videoLineId).name+"】"});
		},
		/**
         * 根据游戏类型获取代号
         */
        getTextByGame(langStr,gameId,index){
            if(gameId==GAME_ID.bac){
                return this.$t(langStr+["z","x"][index]);
            }else if(gameId==GAME_ID.lh){
                return this.$t(langStr+["l","hu"][index]);
            }else if(gameId==GAME_ID.coin){
                return this.$t(langStr+["even","odd"][index]);
            }
			return "";
        },
		/**
         * 额度文本转换
         */
		chipsTxtConvert(amount){
            if(amount==Infinity){
                return "梭哈";
            }else if(amount>=10000){
                return amount/10000+"w";
            }else{
                return amount;
            }
        },
		/**
		 * 关闭引导
		 */
		closeTeach(){
			this.isShowTeach=false;
			localStorage.setItem("isTeach",false);//设置本地存储不再引导
		},
		/**
         * 根据序号获取牌
         */
		getPokerByNum(num) {
            let poker={
                name:num?Math.ceil(num/4):"?",//名称
                shape:"",//形状
                color:""//颜色
            }
            if(num&&num%4==0){
                poker.color="text-dark";
                poker.shape="bi-suit-spade-fill";//黑
            }else if(num%4==1){
                poker.color="text-danger";
                poker.shape="bi-suit-diamond-fill";//方
            }else if(num%4==2){
                poker.color="text-dark";
                poker.shape="bi-suit-club-fill";//梅
            }else if(num%4==3){
                poker.color="text-danger";
                poker.shape="bi-suit-heart-fill";//红
            }else{
				poker.color="text-secondary";
                poker.shape="bi-question-lg";//灰
			}
            if(num>0&&num<5){//1-4
                poker.name="A";
            }else if(num>40&&num<45){//41-44
                poker.name="J";
            }else if(num>44&&num<49){//45-48
                poker.name="Q";
            }else if(num>48&&num<53){//49-52
                poker.name="K";
            }
            return poker;
        },
		/**
         * 游戏是否有路子
         */
		hasPearl(gameId){
            return Object.values(GAME_ROW).find(v=>v.indexOf(Number(gameId))!=-1)?true:false;
        },
		/**
         * 申请进入台面
         */
        goRoom(roomId,gameId){
			if(roomId==this.roomInfo.id){
                useLayer({str:this.$t("roomlist.hearTip")});
                return;
            }else{
				// useLayer({type:LAYER_TYPE.load});
                this.wsSend(COMMAND.all_entertable, gameId, roomId,this.userInfo.id, this.userInfo.guid);
            }
        },
		/**
         * 跳转页面
         */
		goPage(pageName,params,state){
			let p=params||{};
			let s=state||{};
			p.guid=this.userInfo.guid;
			// if(Object.values(GAME_ROW).find(v=>v.indexOf(GAME_ID[this.$route.name])!=-1)&&this.userInfo.guid){//如果当前是真人则退出台面
			if(["home","help","personal"].indexOf(this.$route.name)==-1&&this.userInfo.guid){//如果当前是真人则退出台面
				this.wsSend(COMMAND.all_exittable,this.roomInfo.game||0,this.roomInfo.id||0, this.userInfo.id, this.userInfo.guid);
			}
			if(["home","help","personal"].indexOf(pageName)!=-1){
				this.$store.commit({
					type:MUTATION_TYPE.setRoomInfo
				})
				this.$store.commit({
					type:MUTATION_TYPE.setRoomInfo,
					roomInfo:{
						game:0
					}
				})
			}
			this.$router.push({
				name:pageName,
				params:p,
				state:s
			})
        },
		/**
		 * 注销登录
		 */
		logout(){
			useLayer({
				type:LAYER_TYPE.confirm,
				str:this.$t("app.isLogout"),
				btn:[this.$t("common.yes"),this.$t("common.no")],
				yesFn:(i)=>{
					layer.close(i);
					this.wsSend(COMMAND.all_logout, this.userInfo.id, this.userInfo.guid);
				}
			})
		},
		/**
         * 倒计时
         * @param {Number} tableId 要计时的台面id
         * @param {Number} countdown 计时起始值
         */
        countdown(tableId,countdown){
            if(countdown>0){
                let room=this.roomList.find(v=>v.id==tableId);
				if(room){
					let num=countdown;
					let timer = setInterval(()=>{
						if(this.roomList&&this.roomList.find(v=>v.id==tableId)){//1s后还能找到台
							if(room.countdown<=0||room.countdown!=num){//计时结束或者计时被提前结束
								timer=clearInterval(timer);
								return;
							}
							this.$store.commit({
								type:MUTATION_TYPE.setRoomList,
								room:{
									id:tableId,
									countdown:--num
								}
							})
						}else{//找不到台
							timer&&clearInterval(timer);
						}
					}, 1000);
				}

                // let worker=new Worker("timeWorker.js");//倒计时线程
                // // this.countdownWorkerList[tableId]=worker;
                // worker.postMessage({number:countdown});
                // worker.onmessage=(e)=>{
                //     console.log("----------"+room.countdown,e.data)
                //     if(e.data==0){//||room.countdown-1!=e.data
                //         worker.terminate();
                //         console.log(worker)
                //         worker=null;
                //         // this.countdownWorkerList[tableId]=null;
                //         // console.log(this.countdownWorkerList[tableId])
                //     }
                //     // room.countdown=e.data;
                // }
            }
        },
		/**
		 * 改变游戏项（返回当前改变是否成功）
		 * @param {String} type 类型（tabGame|activeGame）
		 */
		changeGame(gameId,type){
			if(Object.values(GAME_ID).indexOf(Number(gameId))==-1){//游戏未在config中配置
				useLayer({str:"游戏正在加紧开发中，敬请期待..."});
			}else{
				if(gameId!=this.tabGame){//并且不为当前选中的游戏
					let mutationType=type=="tabGame"?MUTATION_TYPE.setTabGame:MUTATION_TYPE.setActiveGame;
					this.$store.commit({
						type:MUTATION_TYPE.setRoomList,
						roomList:null
					})
					this.$store.commit({
						type:mutationType,
						[type]:gameId
					})
					//获取台面列表
					this.wsSend(COMMAND.all_getroomlist, gameId,this.userInfo.id, this.userInfo.guid);
					return true;
				}
			}
		},
		/**
		 * 设置当前显示的模态框
		 */
		setCurrModal(currModal){
			this.$store.commit({
				type:MUTATION_TYPE.setCurrModal,
				currModal:currModal||null
			})
		},
		/**
         * 根据线路列表自动取默认线路id
         */
        getDefaultLineId(roomId){
            let localLine=localStorage.getItem("videoline");
            let lineArr = this.videoLineList.filter(v=>v.rids.indexOf(roomId)!=-1);//获取和当前要进入的台面匹配的视频线路列表
			if(lineArr.length>0){//有和当前台面匹配的视频线路列表
				if(localLine&&lineArr.find(v=>v.id==localLine)){//有缓存线路并且缓存的线路在匹配的线路列表中
					return localLine;//优先使用缓存的线路
				}else{//没有缓存线路
					let iosLine=lineArr.find(v=>v.type=="IOS");//获取ios线路
					if(getSystem()=="ios"&&iosLine){//如果当前为ios系统并且匹配的线路列表中有IOS线路则使用IOS线路
						return iosLine.id;
					}
					return lineArr[0].id;//非ios则直接使用匹配列表中第一个
				}
			}else if(localLine){//没有匹配列表但有缓存线路
				return localLine;//使用缓存的线路
			}else if(this.videoLineList.length>0){//没有匹配列表且没有缓存线路
				let iosLine=this.videoLineList.find(v=>v.type=="IOS");//获取ios线路
				if(getSystem()=="ios"&&iosLine){//如果当前为ios系统并且线路列表中有IOS线路则使用IOS线路
					return iosLine.id;
				}
				return this.videoLineList[0].id;//使用线路列表中第一个
			}
			return "";//啥都没有，返回个屁
        },
		/**
         * 获取最佳牌型
         */
		getCardType(arr){
            // if(
            //     (this.gameInfo.state!=this.gameStateType.wait.id&&(this.player.state!=this.playerStateType.wait.id&&this.player.state!=this.playerStateType.ready.id))||
            //     (this.gameInfo.state==this.gameStateType.wait.id&&(this.player.state==this.playerStateType.wait.id||this.player.state==this.playerStateType.ready.id))
            //     // this.player//有私牌
            // ){//我在玩并且游戏状态不为空闲，或者我没玩并且游戏状态为空闲
            let cardType={
                maxColorOrder: '皇家同花顺',
                colorOrder: '同花顺',
                shijo: '四条',
                gourd: '葫芦',
                color: '同花',
                order: '顺子',
                sanjo: '三条',
                twoPairs: '两对',
                pairs: '对子',
                messy: '散牌'
            };//牌型
                let array=[];
                let reCardArr=arr.map(v=>v==1?53:v==2?54:v==3?55:v==4?56:v).sort((a,b)=>b-a);//牌替换掉A并从大到小排序
                let AArr=reCardArr.filter(v=>[56,55,54,53].indexOf(v)!=-1);//获取所有的A
                let hasColorAArr=AArr.filter(v=>reCardArr.filter(c=>c%4==v%4).length>=5);//筛选有5张及以上同花含A的A
                let colorGroupArr=[];//同花牌分组
                reCardArr.forEach(v=>{
                    let index=v%4>0?(4-v%4):v%4;//黑桃最前，方片最后
                    !colorGroupArr[index]&&(colorGroupArr[index]=[]);
                    colorGroupArr[index].push(v);
                })
                let pointGroupArr=[];//点数分组
                reCardArr.forEach(v=>{
                    let point=Math.ceil(v / 4);
                    !pointGroupArr[point]&&(pointGroupArr[point]=[]);
                    pointGroupArr[point].push(v);
                })
                if(AArr.length>0&&hasColorAArr.length>0){//有A并且存在有同花的A才有必要判断有没有【皇家同花顺】
                    let arr=[];//存放所有有A对应花色的牌组
                    for (let i = 0; i < hasColorAArr.length; i++) {
                        let hasColorACardArr=reCardArr.filter(v=>v%4==hasColorAArr[i]%4);
                        hasColorACardArr.length>=5&&arr.push(hasColorACardArr.slice(0,5));
                    }
                    array = arr.find(v=>!v.some((c,i)=>v[i+1]&&v[i+1]+4!=c)); //判断5张牌有没有顺子(第一个数除外每个数加4都等于上一个数)
                    if(array&&array.length>0){
                        array=array.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                        return {
                            card:array,
                            type:cardType.maxColorOrder
                        }
                    }
                }
                if(colorGroupArr.find(v=>v&&v.length>=5)){//有5张及以上同花才有必要判断有没有【同花顺】
                    let arr=colorGroupArr.filter(v=>v.length>=5);//获取含有5张及以上同花牌分组
                    for (let i = 0; i < arr.length; i++) {
                        for (let j = 0; j < arr[i].length; j++) {
                            if(arr[i][j-1]){//如果有上一张
                                if(Math.ceil(arr[i][j-1]/4)-Math.ceil(arr[i][j]/4)==1){//并且跟上一个点数相差1则连续，不连续说明可能点数一样或者不连续
                                    array.push(arr[i][j]);
                                    if(array.length==5){//凑够5个就不需要继续了
                                        array=array.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                                        return {
                                            card:array,
                                            type:cardType.colorOrder
                                        }
                                    }
                                }
                            }else{//没有上一张
                                if(arr[i].length-j<5){//如果剩余的数不足4个就不需要判断了，因为肯定没有顺子
                                    array=[];
                                    break;
                                }
                                array=[arr[i][j]];
                            }
                        }
                    }
                }
                let fourArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=4).slice(0,4);//找四张
                if(fourArr.length>0){//根据每张牌点数查找数组中有没有4张同点数的牌【四条】
                    array=fourArr.concat(reCardArr.find(v=>fourArr.indexOf(v)==-1)).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:cardType.shijo
                    }
                }
                let threeArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=3).slice(0,3);//找三张
                let twoArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=2&&threeArr.indexOf(v)==-1).slice(0,2);//找两张
                if(threeArr.length>0&&twoArr.length>0){//有三张一样并且两张一样【葫芦】
                    array=threeArr.concat(twoArr).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:cardType.gourd
                    }
                }
                let orderArr=colorGroupArr.find(v=>v&&v.length>=5);//获取含有5张及以上同花牌分组【同花】
                if(orderArr&&orderArr.length>0){
                    array=orderArr.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:cardType.color
                    }
                }
                for (let j = 0; j < reCardArr.length; j++) {//【顺子】
                    if(array.length+(reCardArr.length-j)<5){//如果剩余的数不足4个就不需要判断了，因为肯定没有顺子
                        array=[];
                        break;
                    }
                    if(j==0){
                        array=[reCardArr[j]];
                        continue;
                    }
                    // j==0&&(array=[reCardArr[j]]);//第一张直接放入
                    if(Math.ceil(array[array.length-1]/4)-Math.ceil(reCardArr[j]/4)==1){//如果当前点数与上一张点数相差1则说明两张牌连续
                        array.push(reCardArr[j]);
                        if(array.length==5){//凑够5个就不需要继续了
                            array=array.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                            return {
                                card:array,
                                type:cardType.order
                            }
                        }
                    }else if(Math.ceil(reCardArr[j-1]/4)-Math.ceil(reCardArr[j]/4)>1){//如果当前点数与上一张点数大于1则说明两张牌不连续
                        array=[reCardArr[j]];
                    }
                    // if(reCardArr[j-1]){//如果有上一张
                    //     if(Math.ceil(reCardArr[j-1]/4)-Math.ceil(reCardArr[j]/4)==1){//并且跟上一个点数相差1则连续，不连续说明可能点数一样或者不连续
                    //         array.push(reCardArr[j]);
                    //         if(array.length==5){//凑够5个就不需要继续了
                    //             array=array.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    //             return {
                    //                 card:array,
                    //                 type:cardType.order
                    //             }
                    //         }
                    //     }
                    // }else{//没有上一张
                    //     if(reCardArr.length-j<5){//如果剩余的数不足4个就不需要判断了，因为肯定没有顺子
                    //         array=[];
                    //         break;
                    //     }
                    //     array=[reCardArr[j]];
                    // }
                }
                threeArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=3).slice(0,3);//找三张
                if(threeArr.length>0){//三条
                    array=threeArr.concat(reCardArr.filter(v=>threeArr.indexOf(v)==-1).slice(0,2)).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:cardType.sanjo
                    }
                }
                let twoFirstArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=2).slice(0,2);//找两张
                let twoSecondArr= reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=2&&twoFirstArr.indexOf(v)==-1).slice(0,2);//找两张
                if(twoFirstArr.length>0&&twoSecondArr.length>0){//两对
                    array=twoFirstArr.concat(twoSecondArr,reCardArr.find(v=>twoFirstArr.indexOf(v)==-1&&twoSecondArr.indexOf(v)==-1)).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:cardType.twoPairs
                    }
                }
                twoArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=2).slice(0,2);//找两张
                if(twoArr.length>0){//一对
                    array=twoArr.concat(reCardArr.filter(v=>twoArr.indexOf(v)==-1).slice(0,3)).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:cardType.pairs
                    }
                }
                //散牌
                array=reCardArr.slice(0,5).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                if(array.length>=2){
                    return {
                        card:array,
                        type:cardType.messy
                    }
                }
            //}
            return {
                card:"",
                type:""
            }
        },
	},
	watch: {
		//监听客服快捷消息
		fastChatMsg(newVal){
			if(newVal.serverName!=""&&newVal.msg!=""){
				this.chatMsgTimer&&clearTimeout(this.chatMsgTimer);
				this.chatMsgTimer = setTimeout(() => {
					this.fastChatMsg={
						serverName:"",
						msg:"",
					}
				}, 3000);
			}
		},
		//监听当前台倒计时改变
		"roomInfo.countdown"(newVal){
			if(newVal>0){
				newVal==10&&this.$refs.tenAudioPlayer.playNext();
				this.roomCountdownWorker.postMessage({number:0});
				this.roomCountdownWorker.postMessage({number:1});
			}
		},
		//监听当前台面id改变
		"roomInfo.id"(){
			//由于计时器计数一样的情况下不触发计时器改变的监听，所以需要关掉重开计时器
			this.roomCountdownWorker.postMessage({number:0});
			this.roomCountdownWorker.postMessage({number:1});
		},
		//监听台面游戏改变
		"roomInfo.game"(newVal,oldVal){
			if(newVal!=oldVal&&typeof newVal!="undefined"){//跳不同游戏或跳大厅等其他页面，用undefined判断是因为大厅等其他页面game是0
				this.wsSend(COMMAND.all_getnotice, this.roomInfo.game||0, this.userInfo.id, this.userInfo.guid);//获取公告
			}
		},
		//监听登录
		"userInfo.guid"(newVal){
			if(newVal&&this.audioSwitch.bgAudio){//登录进入并且背景音效有开
				this.$refs.audioPlayer.$refs.audio.play();
			}else{
				this.$refs.audioPlayer.$refs.audio.pause();
			}
		},
		//监听广播html改变
		broadcastHtml(newVal,oldVal){
			if(newVal){
				setTimeout(() => {
					this.broadcastHtml="";
				}, 8000);
			}
		}
	},
}
</script>
